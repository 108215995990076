import React from 'react'

interface IconProps {
  value: string
  color?: string
  style?: object
}

export const Icon: React.FC<IconProps> = ({ value, style }) => (
  <i style={{ ...style }} className={`material-icons `}>
    {value}
  </i>
)
