import React, { FC, ReactElement, useState, useRef, useEffect } from 'react'
import { Flex, Text } from 'rebass'
import {
  Button,
  BtnIcon,
  Label,
} from '../../Common/NeutronComponents/atoms/button/Button'
import { SnackBar } from '../../Common/SnackBar/SnackBar'
import { useDispatch } from 'react-redux'
import {
  TOGGLE_SIDE_DRAWER,
  UPDATE_ROW_LOCATION,
  TRIGGER_MODAL,
  QUEUE_NEW_USER,
} from '../../../store/constants/actionTypes'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store/rootReducer'
import { AuthStateProps } from '../../../interfaces/auth'
import { UIStateProps } from '../../../interfaces/ui'
import { postUser, queueUser } from '../../../store/actions/user'
import {
  displayModal,
  setSnackBarData,
  toggleSideDrawer,
} from '../../../store/actions/ui'
import { RowDataProps, UserDataProps } from '../../../interfaces/user'
import { UserInputRow } from './UserInputRow'
import flushSideDrawer from '../../../utils/flushSideDrawer'
import { AdminStateProps } from '../../../store/reducers/admin'

export const CreateUsers: FC = (): ReactElement => {
  const dispatch = useDispatch()
  const { token = '' } = useSelector<AppState, Partial<AuthStateProps>>(
    ({ auth: { token } }) => ({
      token,
    })
  )
  const { userQueue } = useSelector<AppState, Partial<AdminStateProps>>(
    ({ admin }) => admin
  )
  const [rowToRemove, setRowToRemove] = useState<number>(-1)
  const [rowToEdit, setRowToEdit] = useState<number>(-1)
  const [newIndex, setNewIndex] = useState<number>(10000)

  useEffect(() => {
    setNewIndex(newIndex + 1)
  }, [userQueue])

  const { triggerModal, snackBarData } = useSelector<
    AppState,
    Partial<UIStateProps>
  >(({ ui: { triggerModal, snackBarData } }) => ({
    triggerModal,
    snackBarData,
  }))

  useEffect(() => {
    if (triggerModal?.confirmation === 'leave') {
      setRowToEdit(-1)
      dispatch(
        displayModal({
          type: undefined,
          confirmation: undefined,
          show: false,
        })
      )
      dispatch(queueUser({ user: undefined }))
      dispatch(toggleSideDrawer(flushSideDrawer('closed')))
    }
  }, [triggerModal])

  useEffect(() => {
    if (triggerModal?.confirmation === 'remove') {
      const list = userQueue?.filter(
        (user, index): boolean => index !== rowToRemove
      )
      dispatch(
        queueUser({
          user: list,
        })
      )
      dispatch(
        displayModal({ show: false, type: undefined, confirmation: undefined })
      )
    }
  }, [triggerModal])

  useEffect(() => {
    if (rowToEdit > -1) {
      dispatch(
        displayModal({
          type: 'unsavedEdits',
          confirmation: undefined,
          show: false,
        })
      )
    }
  }, [rowToEdit])

  const inputRowRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const handleAddRowScroll = (): void => {
    const { height } = inputRowRef.current.getBoundingClientRect()
    dispatch({ type: UPDATE_ROW_LOCATION, payload: height })
  }

  const bundleData = (userQueue: Partial<RowDataProps>[]) => {
    const result =
      userQueue &&
      userQueue.reduce((acc: any, d) => {
        const found = acc.find((a: any) => a?.hca34 === d.hca34)
        const value: { orgId: string; role: string[] } = {
          orgId: d.organizationId?.id as string,
          role: d.roles as string[],
        }
        if (!found) {
          acc.push({ ...d, organizations: [value] }) // not found, so need to add data property
        } else {
          found.organizations.push(value) // if found, that means data property exists, so just push new element to found.data.
        }
        return acc
      }, [])

    const formatForPost = result.map(
      ({
        firstName,
        lastName,
        hca34,
        organizations,
        emailAddress,
      }: Partial<UserDataProps>) => ({
        firstName,
        lastName,
        hca34,
        organizations,
        emailAddress,
      })
    )
    dispatch(postUser({ token, users: formatForPost }))
  }
  return (
    <Flex
      flexDirection={'column'}
      sx={{ padding: '40px', position: 'relative' }}
    >
      <Flex flexDirection={'column'}>
        <Flex
          sx={{ width: ['500px', '768px', '968px'] }}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text as={'h5'}>Create Users</Text>
        </Flex>
      </Flex>
      {snackBarData?.message !== '' && (
        <SnackBar
          type={snackBarData?.type || ''}
          message={snackBarData?.message || ''}
        />
      )}
      <Flex alignItems={'center'} flexDirection={'column'} ref={inputRowRef}>
        {userQueue &&
          userQueue.map((row, index) => {
            return (
              <UserInputRow
                rowData={row}
                key={`${row?.hca34}:${row?.organizationLevel}:${
                  row?.organizationId
                }:${row?.roles}:${index.toString()}`}
                rowIndex={index}
                ableToEdit={rowToEdit === index} // rowToEdit === index
                deleteRow={(x: number): number => {
                  setRowToRemove(x)
                  return x
                }}
                editRow={(x: number): number => {
                  setRowToEdit(x)
                  return x
                }}
                saveEdits={(x: Partial<RowDataProps>): unknown => {
                  const userQueueMap = userQueue?.map(user => user)
                  if (userQueueMap) {
                    userQueueMap[index] = x as RowDataProps
                  }

                  dispatch(queueUser({ user: userQueueMap }))
                  setRowToEdit(-1)
                  return
                }}
              />
            )
          })}
      </Flex>
      <Flex
        // alignItems={'center'}
        justifyContent="space-between"
        flexDirection={'column'}
        ref={inputRowRef}
      >
        <UserInputRow
          clearInput={true}
          rowIndex={newIndex}
          ableToAdd={true}
          ableToEdit={false}
          addRow={(formData: Partial<RowDataProps>): unknown => {
            const isEqualToExistingForm = userQueue?.some(user => {
              return (
                user.hca34 === formData.hca34 &&
                user.organizationId?.id === formData.organizationId?.id &&
                user.organizationLevel === formData.organizationLevel
              )
            })
            if (!isEqualToExistingForm) {
              handleAddRowScroll()
              dispatch(queueUser({ user: [...userQueue, formData] }))
              return
            } else {
              dispatch(
                setSnackBarData({
                  type: 'error',
                  message: 'Duplicate user error: please add a distinct user.',
                })
              )
              return
            }
          }}
        />
      </Flex>
      <Flex alignItems={'center'} flexDirection={'column'}>
        {userQueue && (
          <Flex
            sx={{ width: '300px', height: '200px', paddingBottom: '100px' }}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              type={'flat'}
              color={'danger'}
              size={'large'}
              onClick={(): unknown => {
                if (rowToEdit > -1 || userQueue.length > 0) {
                  dispatch(
                    displayModal({
                      show: true,
                      type: 'unsavedEdits',
                      confirmation: undefined,
                    })
                  )
                }

                if (userQueue.length === 0 && rowToEdit === -1) {
                  dispatch(
                    displayModal({
                      show: true,
                      type: 'unsavedEdits',
                      confirmation: undefined,
                    })
                  )
                }

                return
              }}
            >
              <BtnIcon value={'cancel'} position={'left'} />
              <Label value={'Cancel'} />
            </Button>
            <Button
              type={'raised'}
              color={'primary'}
              size={'large'}
              onClick={(): unknown => {
                if (userQueue.length > 0 && rowToEdit === -1) {
                  bundleData(userQueue)
                  dispatch({
                    type: TRIGGER_MODAL,
                    payload: {
                      show: false,
                      type: undefined,
                      confirmation: undefined,
                    },
                  })
                  dispatch({ type: QUEUE_NEW_USER, payload: [] })
                  dispatch({
                    type: TOGGLE_SIDE_DRAWER,
                    payload: { state: 'closed', type: '' },
                  })
                  return
                } else if (rowToEdit > -1) {
                  dispatch(
                    setSnackBarData({
                      type: 'error',
                      message: 'Please confirm edits before saving.',
                    })
                  )
                  return
                } else {
                  return
                }
              }}
            >
              <BtnIcon value={'save'} position={'left'} />
              <Label value={'Save'} />
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
