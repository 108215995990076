import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { RowDataProps, UserDataProps } from '../../../interfaces/user'
import { Flex, Text } from 'rebass'
import _ from 'lodash'
import {
  BtnIcon,
  Button,
  Label,
} from '../../Common/NeutronComponents/atoms/button/Button'
import { UserInputRow } from './UserInputRow'
import { useDispatch, useSelector } from 'react-redux'
import {
  QUEUE_NEW_USER,
  TOGGLE_SIDE_DRAWER,
  TRIGGER_MODAL,
  UPDATE_ROW_LOCATION,
} from '../../../store/constants/actionTypes'
import { deleteUser, postUser, queueUser } from '../../../store/actions/user'
import { AppState } from '../../../store/rootReducer'
import {
  displayModal,
  setSnackBarData,
  toggleSideDrawer,
} from '../../../store/actions/ui'
import flushSideDrawer from '../../../utils/flushSideDrawer'
import { AuthStateProps } from '../../../interfaces/auth'
import { DropDownObjectProps } from '../../../interfaces/interfaces'
import { UIStateProps } from '../../../interfaces/ui'
import { AdminStateProps } from '../../../store/reducers/admin'
import { SnackBar } from '../../Common/SnackBar/SnackBar'

export const EditUsers = ({
  firstName,
  lastName,
  hca34,
  name,
  organizations,
  emailAddress,
}: Partial<UserDataProps>): ReactElement => {
  const { token = '', options = '' } = useSelector<
    AppState,
    Partial<AuthStateProps>
  >(({ auth: { token, options } }) => ({
    token,
    options,
  }))
  const { triggerModal, snackBarData, sideDrawer } = useSelector<
    AppState,
    Partial<UIStateProps>
  >(({ ui: { triggerModal, snackBarData, sideDrawer } }) => ({
    triggerModal,
    snackBarData,
    sideDrawer,
  }))

  const { userQueue } = useSelector<AppState, Partial<AdminStateProps>>(
    ({ admin }) => admin
  )

  const [formattedOrgs, setFormattedOrgs] = useState<
    Partial<RowDataProps>[] | undefined
  >(undefined)

  const dispatch = useDispatch()

  useEffect(() => {
    if (sideDrawer?.state === 'opened') {
      const userOrganizations =
        organizations &&
        organizations.map(row => {
          const levels = ['Company', 'Group', 'Division', 'Facility']
          const level = levels[parseInt(row?.orgLevel || '0')] || levels[0]
          const rowData: Partial<RowDataProps> = {
            emailAddress,
            lastName,
            firstName,
            hca34: hca34 || '',
            organizationLevel: level,
            organizationId:
              (options &&
                options[level]?.filter(
                  (org: DropDownObjectProps) => org.id === row?.orgId
                )[0]) ||
              undefined,
            roles: row?.role || [],
          }
          return rowData
        })
      setFormattedOrgs(userOrganizations)
    }
    if (sideDrawer?.state === 'closed') {
      setFormattedOrgs(undefined)
    }
    return
  }, [sideDrawer])

  useEffect(() => {
    if (formattedOrgs === undefined) {
      dispatch(queueUser({ user: [] }))
    } else if (userQueue?.length === 0) {
      dispatch(queueUser({ user: formattedOrgs }))
    }
  }, [formattedOrgs])

  const [shouldClear, setShouldClear] = useState<boolean>(false)
  const [rowToRemove, setRowToRemove] = useState<number>(-1)
  const [rowToEdit, setRowToEdit] = useState<number>(-1)

  useEffect(() => {
    if (triggerModal?.confirmation === 'leave') {
      setRowToEdit(-1)
      dispatch(
        displayModal({
          type: undefined,
          confirmation: undefined,
          show: false,
        })
      )
      dispatch(queueUser({ user: undefined }))
      dispatch(toggleSideDrawer(flushSideDrawer('closed')))
    }

    if (triggerModal?.confirmation === 'remove') {
      const list = userQueue?.filter(
        (user, index): boolean => index !== rowToRemove
      )
      dispatch(queueUser({ user: list }))
      dispatch(
        displayModal({ show: false, type: undefined, confirmation: undefined })
      )
    }
    if (triggerModal?.confirmation === 'delete') {
      dispatch(deleteUser({ id: hca34 || '', token }))
      dispatch(toggleSideDrawer(flushSideDrawer('closed')))
      dispatch(
        displayModal({ show: false, type: undefined, confirmation: undefined })
      )
    }
  }, [triggerModal])

  useEffect(() => {
    if (rowToEdit > -1) {
      dispatch(
        displayModal({
          type: 'unsavedEdits',
          confirmation: undefined,
          show: false,
        })
      )
    }
  }, [rowToEdit])

  const inputRowRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const handleAddRowScroll = (): void => {
    const { height } = inputRowRef.current.getBoundingClientRect()
    dispatch({ type: UPDATE_ROW_LOCATION, payload: height })
  }

  useEffect(() => {
    if (shouldClear) {
      setShouldClear(false)
    }
  }, [shouldClear])

  const bundleData = (userQueue: Partial<RowDataProps>[]) => {
    if (!token || !hca34) return
    const result =
      userQueue &&
      userQueue.reduce((acc: any, d) => {
        const found = acc.find((a: any) => a?.hca34 === d.hca34)
        const value: { orgId: string; role: string[] } = {
          orgId: d.organizationId?.id as string,
          role: d.roles as string[],
        }
        if (!found) {
          acc.push({ ...d, organizations: [value] }) // not found, so need to add data property
        } else {
          found.organizations.push(value) // if found, that means data property exists, so just push new element to found.data.
        }
        return acc
      }, [])

    const formatForPost = result.map(
      ({
        firstName,
        lastName,
        hca34,
        organizations,
        emailAddress,
      }: Partial<UserDataProps>) => ({
        firstName,
        lastName,
        hca34,
        organizations,
        emailAddress,
      })
    )
    dispatch(postUser({ token, users: formatForPost, source: 'editUser' }))
  }
  return (
    <Flex sx={{ height: '100%' }} flexDirection={'column'}>
      <Flex sx={{ height: 60, padding: '0 .983rem' }}>
        <Text as={'h5'}>Edit User</Text>
      </Flex>
      <Flex sx={{ height: '100%' }}>
        <Flex sx={{ width: 429, height: '100%' }}>
          <Flex
            flexDirection={'column'}
            justifyContent={'flex-start'}
            sx={{
              height: '100%',
              flex: '0 1 350px',
              maxWidth: 400,
              background: '#f5f5f5',
              padding: '1.3rem .983rem',
              boxShadow: 'inset -10px 10px 4px -4px rgba(0,0,0,.15)',
              borderRight: '1px solid rgba(0,0,0,.16)',
              overflow: 'hidden',
            }}
          >
            <Flex flexDirection={'column'} sx={{ marginTop: '.983rem' }}>
              <p style={{ fontWeight: 700, fontSize: 14 }}>Name</p>
              <p style={{ fontSize: 18 }}>{name}</p>
            </Flex>
            <Flex flexDirection={'column'}>
              <p style={{ fontWeight: 700, fontSize: 14 }}>3-4 ID</p>
              <p style={{ fontSize: 18 }}>{hca34}</p>
            </Flex>
            <Flex flexDirection={'column'} sx={{ marginTop: '.983rem' }}>
              <Button
                type={'flat'}
                color={'danger'}
                size={'large'}
                onClick={(): unknown => {
                  if (hca34) {
                    dispatch(
                      displayModal({
                        show: true,
                        type: 'deleteUser',
                        confirmation: undefined,
                      })
                    )
                  }
                  return
                }}
              >
                <Label value={'delete'} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection={'column'}
          sx={{
            height: '100%',
            width: '100%',
            background: 'white',
            padding: '1.3rem .983rem',
            boxShadow: 'inset 0px 10px 4px -4px rgba(0,0,0,.15)',
            overflow: 'scroll',
            marginBottom: 200,
            justifyContent: 'flex-start',
          }}
        >
          {snackBarData?.message !== '' && (
            <SnackBar
              type={snackBarData?.type || ''}
              message={snackBarData?.message || ''}
            />
          )}
          <Flex flexDirection={'column'} ref={inputRowRef}>
            {userQueue &&
              userQueue.map(
                (row: Partial<RowDataProps> | undefined, index: number) => {
                  return (
                    <UserInputRow
                      rowData={row}
                      key={`${row?.hca34}:${
                        row?.organizationLevel
                      }:${index.toString()}`}
                      rowIndex={index}
                      ableToEdit={rowToEdit === index} // rowToEdit === index
                      deleteRow={(x: number): number => {
                        setRowToRemove(x)
                        return x
                      }}
                      editRow={(x: number): number => {
                        setRowToEdit(x)
                        return x
                      }}
                      saveEdits={(x): unknown => {
                        const userQueueMap =
                          userQueue &&
                          userQueue.map((user: Partial<RowDataProps>) => user)
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        userQueueMap[index] = x as Partial<RowDataProps>
                        const isEqualToExistingForm =
                          userQueue &&
                          userQueue.some((user: Partial<RowDataProps>) => {
                            return (
                              user.organizationId?.id ===
                                x.organizationId?.id &&
                              user.organizationLevel === x.organizationLevel
                            )
                          })
                        if (!isEqualToExistingForm) {
                          dispatch(queueUser({ user: userQueueMap }))
                          setRowToEdit(-1)
                          return
                        } else if (
                          isEqualToExistingForm &&
                          rowToEdit ===
                            userQueue
                              .map(user => user.organizationId?.id)
                              .indexOf(x.organizationId?.id)
                        ) {
                          dispatch(queueUser({ user: userQueueMap }))
                          setRowToEdit(-1)
                          return
                        } else {
                          dispatch(
                            setSnackBarData({
                              type: 'error',
                              message:
                                'Duplicate user error: please add a distinct user.',
                            })
                          )
                          return
                        }
                      }}
                    />
                  )
                }
              )}
          </Flex>
          <Flex flexDirection={'column'} ref={inputRowRef}>
            <UserInputRow
              editingUser={hca34}
              clearInput={false}
              rowIndex={100000}
              ableToAdd={true}
              ableToEdit={false}
              addRow={(formData: Partial<RowDataProps>): unknown => {
                const isEqualToExistingForm =
                  userQueue &&
                  userQueue.some((user: Partial<RowDataProps>) => {
                    return (
                      user.organizationId?.id === formData.organizationId?.id &&
                      user.organizationLevel === formData.organizationLevel
                    )
                  })
                if (!isEqualToExistingForm) {
                  handleAddRowScroll()
                  dispatch(
                    queueUser({
                      user: [
                        ...userQueue,
                        Object.assign({}, formData, {
                          hca34,
                          firstName,
                          lastName,
                          emailAddress,
                        }),
                      ],
                    })
                  )
                  return
                } else {
                  dispatch(
                    setSnackBarData({
                      type: 'error',
                      message:
                        'Duplicate user error: please add a distinct user.',
                    })
                  )
                  return
                }
              }}
            />
          </Flex>

          <Flex alignItems={'center'} flexDirection={'column'}>
            <Flex
              sx={{ width: '300px', height: '200px', paddingBottom: '100px' }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                type={'flat'}
                color={'danger'}
                size={'large'}
                onClick={(): unknown => {
                  if (
                    (userQueue &&
                      _.differenceWith(
                        userQueue,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        formattedOrgs,
                        _.isEqual
                      ).length > 0) ||
                    rowToEdit !== -1 ||
                    (userQueue &&
                      _.differenceWith(formattedOrgs, userQueue, _.isEqual)
                        .length > 0)
                  ) {
                    // bundleData(userQueue)
                    dispatch({
                      type: TRIGGER_MODAL,
                      payload: {
                        show: true,
                        type: 'unsavedEdits',
                        confirmation: undefined,
                      },
                    })
                    return
                  } else if (rowToEdit > -1) {
                    dispatch(
                      setSnackBarData({
                        type: 'error',
                        message: 'Please confirm edits before saving.',
                      })
                    )
                    return
                  } else {
                    dispatch(queueUser({ user: undefined }))
                    dispatch({
                      type: TOGGLE_SIDE_DRAWER,
                      payload: { state: 'closed', type: 'editUser' },
                    })
                    return
                  }
                }}
              >
                <BtnIcon value={'cancel'} position={'left'} />
                <Label value={'Cancel'} />
              </Button>
              <Button
                type={'raised'}
                color={'primary'}
                size={'large'}
                onClick={(): unknown => {
                  if (userQueue && userQueue?.length > 0 && rowToEdit === -1) {
                    bundleData(userQueue)
                    dispatch({
                      type: TOGGLE_SIDE_DRAWER,
                      payload: { state: 'closed', type: 'editUser' },
                    })
                    return
                  } else {
                    dispatch(
                      setSnackBarData({
                        type: 'error',
                        message: 'Please save your row edits.',
                      })
                    )
                    return
                  }
                }}
              >
                <BtnIcon value={'save'} position={'left'} />
                <Label value={'Save'} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
