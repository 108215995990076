import React from 'react'
import { Flex } from 'rebass'

interface ButtonProps {
  color: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'disabled'
  value: string
}

export const Chip: React.FC<ButtonProps> = ({
  color = 'primary',
  value,
  children,
}) => {
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        margin: '2.5px 5px',
        height: 19,
        padding: '0 15px',
        textAlign: 'center',
        borderRadius: '25px',
        border: `3px solid ${color}`,
      }}
      className={`neutron-button
          neutron-button--${color?.toLowerCase()} neutron-button--raised neutron-button--small`}
    >
      <span style={{ color }}>{value}</span>
      {children}
    </Flex>
  )
}
