import React, { useState, ReactElement, useEffect } from 'react'

interface InputProps {
  placeholder?: string
  isValid?: boolean
  captureInput: (x: string) => void
  name?: string
  disabled?: boolean
  defaultValue: string
  clearInput?: boolean
}

export const Input: React.FC<InputProps> = ({
  placeholder,
  clearInput,
  isValid = true,
  captureInput,
  name,
  disabled,
  children,
  defaultValue,
}): ReactElement => {
  const [inputValue, setInputValue] = useState<string>(defaultValue)

  useEffect(() => {
    if (clearInput === true) {
      setInputValue('')
    }
  }, [clearInput])

  return (
    <form
      className={`neutron-input ${
        disabled ? 'neutron-input--is-disabled' : ''
      } ${!isValid && !disabled ? 'neutron-input--has-error' : ''}`}
      style={{
        display: 'flex',
        position: 'relative',
      }}
      onSubmit={e => e.preventDefault()}
    >
      <input
        value={inputValue}
        placeholder={placeholder}
        className="neutron-input__text-field"
        type="text"
        name={name}
        aria-disabled={disabled}
        disabled={disabled}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
          setInputValue(ev.target.value)
          captureInput(ev.target.value)
        }}
      />
      {children}
    </form>
  )
}
