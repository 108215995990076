import React, { FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { toggleSideDrawer } from '../../../../../store/actions/ui'

import './Table.css'
import { UserDataProps } from '../../../../../interfaces/user'

interface MetricsHeaderDataProps {
  key: string
  sortable: boolean
  sortFunction: (x: string, z: boolean) => void
  label: string
  span: number
}

interface TableProps {
  headers: MetricsHeaderDataProps[]
  data: UserDataProps[]
  sortDirection: boolean
  sortKey: string
}

export const Table: FC<TableProps> = ({
  headers,
  data,
  sortDirection,
  sortKey,
}) => {
  const mappedHeaders = headers.map(header => header.key)
  const dispatch = useDispatch()

  return (
    <div className="neutron-container-table" style={{ padding: 0 }}>
      <table className="neutron-table">
        <thead className="neutron-table__head">
          <tr>
            {headers.map(({ sortable, key, label, sortFunction, span }) =>
              sortable ? (
                <th
                  key={key}
                  className="neutron-table__header neutron-padding--left-20"
                  role="columnheader"
                  colSpan={span}
                  onClick={(): unknown => {
                    sortFunction(key, !sortDirection)
                    return
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {label}
                  <i
                    style={{
                      color:
                        sortDirection && key === sortKey
                          ? '#0085ca'
                          : '#898b8e',
                    }}
                    className="material-icons
                neutron-table__sort-icon"
                  >
                    {sortDirection && key === sortKey
                      ? 'arrow_upward'
                      : 'arrow_downward'}
                  </i>
                </th>
              ) : (
                <th
                  key={key}
                  className="neutron-table__header neutron-padding--left-20"
                  role="columnheader"
                  colSpan={span}
                >
                  {label}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="neutron-table__body" role="cell">
          {data.map((datum: UserDataProps, index: number) => {
            return (
              <tr
                key={Object.values(datum).join() + String(index)}
                className="neutron-table__row"
              >
                {mappedHeaders.map((key: string, index: number) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const value = (datum as any)[key]
                  const orgs = key === 'roleAndOrg' ? value.split(', ') : []
                  const orgCount =
                    key === 'roleAndOrg' && orgs.length > 1
                      ? `(${orgs.length}) Total Organizations`
                      : ''
                  return (
                    <td
                      key={key}
                      style={{
                        padding: '0.938rem',
                      }}
                      role="cell"
                      colSpan={headers[index].span}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '1 1 200px',
                          }}
                        >
                          {value}
                        </span>
                        {key === 'roleAndOrg' && (
                          <span
                            style={{ textAlign: 'right', flex: '0 1 200px' }}
                          >
                            {orgCount}
                          </span>
                        )}
                      </div>
                    </td>
                  )
                })}
                <td
                  style={{
                    padding: '15px 30px 0 0',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  onClick={(): unknown =>
                    dispatch(
                      toggleSideDrawer({
                        state: 'opened',
                        type: 'editUser',
                        data: datum,
                      })
                    )
                  }
                >
                  <i
                    className="material-icons
                neutron-table__sort-icon"
                  >
                    edit
                  </i>
                </td>
              </tr>
            )
          })}
        </tbody>
        {/*<tfoot>*/}
        {/*  <tr className="neutron-table__row">*/}
        {/*    <td*/}
        {/*      className="neutron-table__cell neutron-padding--left-20"*/}
        {/*      role="cell"*/}
        {/*      colSpan={2}*/}
        {/*    >*/}
        {/*      <div className="neutron-checkbox neutron-margin--right-10">*/}
        {/*        <input*/}
        {/*          className="neutron-checkbox__box"*/}
        {/*          type="checkbox"*/}
        {/*          aria-checked="false"*/}
        {/*          name=""*/}
        {/*          value=""*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      Selectable Row*/}
        {/*    </td>*/}
        {/*  </tr>*/}
        {/*</tfoot>*/}
      </table>
    </div>
  )
}
