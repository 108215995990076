import {
  GET_ALL_USERS,
  ADD_NEW_SUCCESS,
  FETCHING,
  GET_USERS_COUNT,
  ADD_NEW_ERROR,
  USER_NOT_FOUND,
  USER_MATCHES_SEARCH,
  QUEUE_NEW_USER,
  DELETE_USER_SUCCESS,
} from '../constants/actionTypes'
import { Action } from 'redux'
import environment from '../../environment'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { RowDataProps, UserDataProps } from '../../interfaces/user'
import { setSnackBarData } from './ui'

const serverUrl = environment._serverUrl
export const getAllUsers = ({
  token,
  increment = 10,
  page,
  direction = 'asc',
  key = 'lastName',
}: {
  token: string
  increment: number
  page: number
  direction?: string | undefined
  key?: string | undefined
}): ThunkAction<void, UserDataProps[], unknown, Action> => async (
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
): Promise<void> => {
  dispatch({ type: FETCHING, payload: true })
  try {
    const getUsers = await fetch(
      serverUrl +
        `users?$top=${increment}&$skip=${(page - 1) *
          increment}&$orderBy=${key} ${direction}`,
      {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        referrerPolicy: 'no-referrer',
      }
    )
    const status = await getUsers.status

    if (status === 200) {
      const users = await getUsers.json()
      const formattedUsers = users.map((user: UserDataProps) => {
        return {
          ...user,
          name: `${user?.lastName}, ${user?.firstName}`,
          roleAndOrg: user?.organizations
            ?.map(
              authorization =>
                `${authorization?.orgName} (${authorization?.role?.join(', ')})`
            )
            .join(', '),
        }
      })

      dispatch({ type: GET_ALL_USERS, payload: formattedUsers })
      dispatch({ type: FETCHING, payload: false })
      dispatch({
        type: GET_USERS_COUNT,
        payload: users[0]?.totalRecords || 10000,
      })
    } else {
      dispatch({ type: ADD_NEW_ERROR, payload: status.toString() })
      dispatch({ type: FETCHING, payload: false })
    }
  } catch (ex) {
    console.error(ex)
  }
  return
}

export const deleteUser = ({
  token,
  id,
}: {
  token: string
  id: string
}): ThunkAction<
  void,
  {
    token: string
    id: string
  },
  unknown,
  Action
> => async (
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
): Promise<void> => {
  try {
    const user = await fetch(serverUrl + `users`, {
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(id),
    })

    const status = await user.status
    if (status === 204) {
      dispatch(getAllUsers({ token, increment: 10, page: 1 }))
      dispatch({ type: DELETE_USER_SUCCESS, payload: status })
    } else {
      dispatch({ type: ADD_NEW_ERROR, payload: status.toString() })
    }
  } catch (ex) {
    // dispatch({ type: ADD_NEW_ERROR, payload: ex })
  }
}

export const searchUsers = ({
  token,
  search,
  increment,
  page,
}: {
  token: string
  search: string
  increment: number
  page: number
}): ((
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
) => Promise<void>) => async (
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
): Promise<void> => {
  dispatch({ type: FETCHING, payload: true })
  try {
    const getUsers = await fetch(
      serverUrl +
        `users?searchString=${search}&$top=${increment}&$skip=${(page - 1) *
          increment}`,
      {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        referrerPolicy: 'no-referrer',
      }
    )

    const status = await getUsers.status
    if (status === 404) {
      dispatch({ type: USER_NOT_FOUND, payload: true })
      dispatch({ type: USER_MATCHES_SEARCH, payload: false })
      dispatch({ type: GET_ALL_USERS, payload: [] })
    } else if (status === 200) {
      const users = await getUsers.json()
      const formattedUsers = users.map((user: UserDataProps) => {
        return {
          ...user,
          name: `${user?.lastName}, ${user?.firstName}`,
          roleAndOrg: user?.organizations
            ?.map(
              authorization =>
                `${authorization?.orgName} (${authorization?.role?.join(', ')})`
            )
            .join(', '),
        }
      })
      dispatch({ type: GET_ALL_USERS, payload: formattedUsers })
      dispatch({
        type: GET_USERS_COUNT,
        payload: users[0]?.totalRecords || 10000,
      })
      dispatch({ type: USER_NOT_FOUND, payload: false })
      dispatch({ type: USER_MATCHES_SEARCH, payload: true })
    }
  } catch (ex) {
    console.log(ex)
    // dispatch({ type: EXCEPTION_CAUGHT, payload: ex })
  } finally {
    dispatch({ type: FETCHING, payload: false })
  }
}

export const postUser = ({
  token,
  users,
  source,
}: {
  source?: string | undefined
  token: string
  users: Partial<UserDataProps>[]
}): ThunkAction<
  void,
  {
    token: string
    users: Partial<UserDataProps>[]
    source?: string | undefined
  },
  unknown,
  Action
> => async (
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
): Promise<void> => {
  try {
    const body = users

    const updatedUser = await fetch(serverUrl + `users/bulk`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(body),
    })

    const success = await updatedUser.status

    if (success) {
      dispatch({ type: ADD_NEW_SUCCESS, payload: success })
      if (source !== 'editUser') {
        dispatch(getAllUsers({ token, increment: 10, page: 1 }))
      }
    }
  } catch (ex) {
    dispatch({ type: ADD_NEW_ERROR, payload: ex.status.toString() })
  }
}

export const verifyUser34 = async ({
  token,
  hca34,
}: {
  token: string
  hca34: string
}) => {
  try {
    const checkUser = await fetch(`${serverUrl}users/${hca34}/profile/flat`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        'Access-Control-Allow-Origin': '*',
      },
      referrerPolicy: 'no-referrer',
    })

    const userExists = (await checkUser.status) === 200

    if (userExists) return 'userExists'

    const verified34 = await fetch(serverUrl + `users/${hca34}/ad-lookup`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        'Access-Control-Allow-Origin': '*',
      },
      referrerPolicy: 'no-referrer',
    })
    return await verified34.json()
  } catch (ex) {
    // dispatch({ type: 'EXCEPTION_CAUGHT', payload: ex })
  }
}

export const queueUser = ({
  user,
}: {
  user: Partial<RowDataProps>[] | undefined
}): ThunkAction<
  void,
  {
    users: Partial<RowDataProps>[]
  },
  unknown,
  Action
> => async (
  dispatch: ThunkDispatch<Partial<RowDataProps>[], unknown, Action>
): Promise<void> => {
  try {
    if (user === undefined) {
      dispatch({ type: QUEUE_NEW_USER, payload: [] })
    } else {
      dispatch({ type: QUEUE_NEW_USER, payload: user })
    }
  } catch (ex) {
    dispatch({ type: ADD_NEW_ERROR, payload: ex.status.toString() })
  }
}
