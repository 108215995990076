import {
  TOGGLE_SIDE_DRAWER,
  SNACKBAR_DATA,
  TRIGGER_MODAL,
  ADD_NEW_ERROR,
  ADD_NEW_SUCCESS,
} from '../constants/actionTypes'
import { ModalProps, SideDrawerProps, SnackBarProps } from '../../interfaces/ui'
import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { RowDataProps, UserDataProps } from '../../interfaces/user'
import { queueUser } from './user'
import { DropDownObjectProps } from '../../interfaces/interfaces'

export const toggleSideDrawer = (
  data: Partial<SideDrawerProps>
): ((
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
) => Promise<void>) => async (
  dispatch: ThunkDispatch<UserDataProps[], unknown, Action>
): Promise<void> => {
  dispatch({ type: TOGGLE_SIDE_DRAWER, payload: data })
}

export const setSnackBarData = ({
  type,
  message,
}: {
  type: string
  message: string
}): ThunkAction<void, SnackBarProps, unknown, Action> => async (
  dispatch: ThunkDispatch<SnackBarProps[], unknown, Action>
): Promise<void> => {
  dispatch({ type: SNACKBAR_DATA, payload: { type, message } })
  setTimeout(() => {
    dispatch({ type: SNACKBAR_DATA, payload: { type: '', message: '' } })
    dispatch({ type: ADD_NEW_ERROR, payload: '' })
    dispatch({ type: ADD_NEW_SUCCESS, payload: -1 })
  }, 3000)
}

export const displayModal = ({
  show,
  type,
  confirmation,
}: ModalProps): ThunkAction<void, ModalProps, unknown, Action> => async (
  dispatch: ThunkDispatch<SnackBarProps[], unknown, Action>
): Promise<void> => {
  dispatch({ type: TRIGGER_MODAL, payload: { show, type, confirmation } })
}
