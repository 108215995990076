import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import environment from '../../environment'
import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
} from '@openid/appauth'
import { AuthorizationRequest } from '@openid/appauth/built/authorization_request'
import {
  GRANT_TYPE_AUTHORIZATION_CODE,
  TokenRequest,
} from '@openid/appauth/built/token_request'
import { BaseTokenRequestHandler } from '@openid/appauth/built/token_request_handler'
import { pingConfig } from '../../pingConfig'
import {
  GET_AUTH_TOKEN,
} from '../../store/constants/actionTypes'
import {getAuthenticatedUser} from "../../store/actions/auth";

const {
  qaOpenIdConnectUrl,
  openIdConnectUrl,
  prodOpenIdConnectUrl,
  qaClientId,
  clientId,
  prodClientId,
  scope,
  responseType,
  state,
  extras,
} = pingConfig

interface User {
  name: string
}

const Authentication: FC = ({ children }): ReactElement => {
  const [token, setToken] = useState('')
  const [user, setUser] = useState<User | undefined>()
  
  const dispatch = useDispatch()
let openIdConnectUrlEnv = ''
let clientIdEnv = ''
switch (environment._type) {
  case 'local':
    openIdConnectUrlEnv = openIdConnectUrl
    clientIdEnv = clientId
    break;
  case 'dev':
    openIdConnectUrlEnv = openIdConnectUrl
    clientIdEnv = clientId
    break;
  case 'qa':
    openIdConnectUrlEnv = qaOpenIdConnectUrl
    clientIdEnv = qaClientId
    break;
  case 'prod':
    openIdConnectUrlEnv = prodOpenIdConnectUrl
    clientIdEnv = prodClientId
    break;

  default:
    break;
}
  const getQueryStringParams = (query: any): any => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params: any, param: any) => {
            let [key, value] = param.split('=')
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : ''
            return params
          }, {})
      : {}
  }

  const params = getQueryStringParams(window.location.search)
  const { code } = params

  useEffect(() => {
    const authenticateIfNecessary = async () => {
      const fetchConfig = () => {
        return AuthorizationServiceConfiguration.fetchFromIssuer(
          openIdConnectUrlEnv,
        )
          .then(response => {
            console.log('Fetched service configuration', response)
            return response
          })
          .catch(error => {
            console.log('Something bad happened', error)
            throw error
          })
      }
      const makeAuthRequest = (configuration: any) => {
        const authorizationHandler = new RedirectRequestHandler()
        const request = new AuthorizationRequest({
          client_id: clientIdEnv,
          redirect_uri: window.location.origin + '/redirect',
          scope,
          response_type: responseType,
          state,
          extras
        })
        return authorizationHandler.performAuthorizationRequest(
          configuration,
          request
        )
      }

      const tokenHandler = new BaseTokenRequestHandler()

      if (code) {
        const lStorageKey = localStorage.getItem(
          'appauth_current_authorization_request'
        )
        cleanLocalStorage([
          'appauth_current_authorization_request',
          lStorageKey + '_appauth_authorization_request',
          lStorageKey + '_appauth_authorization_service_configuration',
        ])
        let extras = {}
        if (lStorageKey) {
          const value = localStorage.getItem(
            lStorageKey + '_appauth_authorization_request'
          )
          if (value) {
            const req = JSON.parse(value)
            // @ts-ignore
            extras['code_verifier'] = req?.internal['code_verifier']
          }
        }

        const request = new TokenRequest({
          client_id: clientIdEnv,
          redirect_uri: window.location.origin + '/redirect',
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code,
          refresh_token: undefined,
          extras,
        })

        fetchConfig().then(res => {
          tokenHandler.performTokenRequest(res, request).then(response => {
            dispatch({
              type: GET_AUTH_TOKEN,
              payload: 'Bearer ' + response.accessToken,
            })
            const user = JSON.parse(
              window.atob(response.accessToken.split('.')[1])
            )
            const { subject} = user
            dispatch(getAuthenticatedUser({ hca34: subject, token:  'Bearer ' + response.accessToken }))
          })
        })
        return user
      } else {
        fetchConfig().then(res => {
          makeAuthRequest(res)
        })
      }
    }

    authenticateIfNecessary()
  }, [token])

  return <div>{children}</div>
}

export default Authentication

function cleanLocalStorage(excluding: string[]) {
  const allStorageItems = Object.keys(localStorage)
  allStorageItems.forEach(item => {
    if (!excluding.some(key => key === item)) {
      localStorage.removeItem(item)
    }
  })
}
