import {
  GET_ALL_USERS,
  GET_USERS_COUNT,
  GET_AD_USERS,
  GET_USER,
  FETCHING,
  QUEUE_NEW_USER,
} from '../constants/actionTypes'
import { AnyAction } from 'redux'
import { RowDataProps, UserDataProps } from '../../interfaces/user'

export interface AdminStateProps {
  fetching: boolean
  users: UserDataProps[]
  user: object
  adUsers: object[]
  totalUsers: number
  userQueue: RowDataProps[] | undefined
}

const initialState: AdminStateProps = {
  fetching: false,
  users: [],
  user: {},
  adUsers: [],
  totalUsers: 0,
  userQueue: [],
}

const admin = (state = initialState, action: AnyAction): AdminStateProps => {
  switch (action.type) {
    case FETCHING:
      return { ...state, fetching: action.payload }
    case GET_ALL_USERS:
      return { ...state, users: action.payload }
    case GET_USERS_COUNT:
      return { ...state, totalUsers: action.payload }
    case GET_USER:
      return { ...state, user: action.payload }
    case GET_AD_USERS:
      return { ...state, adUsers: action.payload }
    case QUEUE_NEW_USER:
      return { ...state, userQueue: action.payload }
    default:
      return state
  }
}

export default admin
