import styled from 'styled-components'
import { Box } from 'rebass'

export const PlaceholderBox = styled(Box)`
     background-image: linear-gradient(
        to right,
        transparent 33%,
        rgba(255,255,255,0.2) 50%,
        transparent 66%
      );
      background-size:300% 100%;
      animation: shine 2s infinite;
    }

    @keyframes shine {
      0% {
        background-position: right;    
      }
    }
`
