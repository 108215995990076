import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { Flex } from 'rebass'
import { TRIGGER_MODAL } from '../../../store/constants/actionTypes'
import { Button, Label } from '../NeutronComponents/atoms/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store/rootReducer'
import { AuthStateProps } from '../../../interfaces/auth'
import useEventListener from '@use-it/event-listener'
import { displayModal } from '../../../store/actions/ui'

export const DeleteUser: FC = (): ReactElement => {
  const dispatch = useDispatch()
  const { firstName } = useSelector<AppState, Partial<AuthStateProps>>(
    ({ auth: { firstName } }) => ({
      firstName,
    })
  )

  return (
    <Flex
      sx={{
        borderRadius: 5,
        boxShadow: '0 2px 7px 4px rgba(0,0,0,0.13)',
        padding: '15px 30px',
        width: 600,
        background: '#fff',
      }}
      flexDirection="column"
    >
      <div
        style={{
          backgroundImage: `url(${require('../../../assets/deleteuser.gif')})`,
          backgroundRepeat: 'no-repeat',
          height: '200px',
          width: '200px',
          backgroundSize: 'cover',
          margin: '20px auto',
          borderRadius: '50%',
          boxShadow: '0 2px 7px 4px rgba(0,0,0,0.13)',
        }}
      />
      <p style={{ fontSize: 20, fontWeight: 'bold' }}>
        {firstName}, set phasers to stun!
      </p>
      <p>Delete user?</p>
      <Flex justifyContent={'center'} alignItems={'center'}>
        <Button
          type={'flat'}
          color={'danger'}
          onClick={() =>
            dispatch(
              displayModal({
                show: false,
                type: undefined,
                confirmation: 'cancel',
              })
            )
          }
          size={'large'}
        >
          <Label className="dog" value={'Cancel'} />
        </Button>
        <Button
          type={'flat'}
          color={'success'}
          onClick={() => {
            dispatch(
              displayModal({
                show: false,
                type: 'deleteUser',
                confirmation: 'delete',
              })
            )
          }}
          size={'large'}
        >
          <Label className="cat" value={'Delete'} />
        </Button>
      </Flex>
    </Flex>
  )
}
