import { Input } from '../NeutronComponents/atoms/input/Input'
import { Icon } from '../NeutronComponents/atoms/icon/Icon'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDebounce } from '../../../utils/useDebounce'
import { useSelector } from 'react-redux'
import { UIStateProps } from '../../../interfaces/ui'
import { AppState } from '../../../store/rootReducer'

interface SearchProps {
  onDebounce: (term: string) => void
}

export const Search: ({ onDebounce }: SearchProps) => React.ReactElement = ({
  onDebounce,
}: SearchProps): ReactElement => {
  const [userInput, setUserInput] = useState<string>('')
  const debouncedSearchTerm = useDebounce(userInput, 1000)

  useEffect((): void => {
    onDebounce(debouncedSearchTerm)
    return
  }, [debouncedSearchTerm])

  const { userNotFound, userMatchesSearch } = useSelector<
    AppState,
    Partial<UIStateProps>
  >(({ ui: { userNotFound, userMatchesSearch } }) => ({
    userNotFound,
    userMatchesSearch,
  }))
  let background
  let icon
  if (userNotFound) {
    background = '#960b1c'
    icon = 'not_interested'
  } else if (userMatchesSearch) {
    background = '#599662'
    icon = 'done'
  } else {
    background = '#005589'
    icon = 'search'
  }
  return (
    <div
      className="neutron-right-elements neutron-navbar--search"
      style={{ width: '18.75rem', display: 'flex', alignItems: 'center' }}
      role="search"
    >
      <Input
        defaultValue={''}
        placeholder="Search"
        captureInput={(e): void => setUserInput(e)}
      >
        <div
          style={{
            paddingLeft: 3,
            position: 'absolute',
            width: '30px',
            height: '30px',
            top: 5,
            right: 5,
            borderRadius: 3,
          }}
        >
          <Icon style={{ color: background, marginTop: 3 }} value={icon} />
        </div>
      </Input>
    </div>
  )
}
