// UI
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER'
export const UPDATE_ROW_LOCATION = 'UPDATE_ROW_LOCATION'
export const ADD_NEW_ERROR = 'ADD_NEW_ERROR'
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS'
export const ADD_NEW_SUCCESS = 'ADD_NEW_SUCCESS'
export const SNACKBAR_DATA = 'SNACKBAR_DATA'
export const USER_NOT_FOUND = 'USER_NOT_FOUND'
export const USER_MATCHES_SEARCH = 'USER_MATCHES_SEARCH'
export const TRIGGER_MODAL = 'TRIGGER_MODAL'
export const DELETE_USER_SUCCESS = ' DELETE_USER_SUCCESS'

// ADMIN
export const FETCHING = 'FETCHING'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_USER = 'GET_USER'
export const GET_AD_USERS = 'GET_AD_USERS'
export const GET_USERS_COUNT = 'GET_USERS_COUNT'
export const QUEUE_NEW_USER = 'QUEUE_NEW_USER'

// AUTH
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN'
export const GET_AUTH_USER = 'GET_AUTH_USER'
export const FETCHING_AUTH_USER = 'FETCHING_AUTH_USER'
