import {
  TOGGLE_SIDE_DRAWER,
  UPDATE_ROW_LOCATION,
  ADD_NEW_SUCCESS,
  ADD_NEW_ERROR,
  SNACKBAR_DATA,
  USER_NOT_FOUND,
  USER_MATCHES_SEARCH,
  TRIGGER_MODAL,
  DELETE_USER_SUCCESS,
} from '../constants/actionTypes'
import { AnyAction } from 'redux'
import { UIStateProps } from '../../interfaces/ui'

const initialState: UIStateProps = {
  sideDrawer: {
    state: 'closed',
    type: '',
    data: {
      firstName: '',
      lastName: '',
      hca34: '',
      name: '',
      roleAndOrg: '',
      organizations: [],
      emailAddress: '',
    },
    updatedRowLocation: undefined,
  },
  success: {
    addUserSuccess: -1,
    deleteUserSuccess: -1,
  },
  error: '',
  snackBarData: { type: '', message: '' },
  userNotFound: false,
  userMatchesSearch: false,
  triggerModal: {
    show: false,
    type: undefined,
    confirmation: undefined,
  },
}

const ui = (state = initialState, action: AnyAction): UIStateProps => {
  const { sideDrawer } = state
  switch (action.type) {
    case TOGGLE_SIDE_DRAWER:
      return { ...state, sideDrawer: { ...sideDrawer, ...action.payload } }
    case UPDATE_ROW_LOCATION:
      const newSideDrawerData = Object.assign({}, sideDrawer, {
        updatedRowLocation: action.payload,
      })
      return { ...state, sideDrawer: { ...sideDrawer, ...newSideDrawerData } }
    case ADD_NEW_SUCCESS:
      return {
        ...state,
        success: { addUserSuccess: action.payload, deleteUserSuccess: -1 },
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        success: { addUserSuccess: -1, deleteUserSuccess: action.payload },
      }
    case ADD_NEW_ERROR:
      return { ...state, error: action.payload }
    case SNACKBAR_DATA:
      return { ...state, snackBarData: action.payload }
    case USER_NOT_FOUND:
      return { ...state, userNotFound: action.payload }
    case USER_MATCHES_SEARCH:
      return { ...state, userMatchesSearch: action.payload }
    case TRIGGER_MODAL:
      return { ...state, triggerModal: action.payload }
    default:
      return state
  }
}

export default ui
