import React, { FC, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../../store/rootReducer'
import { AdminStateProps } from '../../../../../store/reducers/admin'
import { displayModal } from '../../../../../store/actions/ui'

import { Tab } from '../../molecules/tabs/Tab'
import { Link } from 'react-router-dom'
import { Image } from '../../atoms/image/Image'
import { AvatarImage } from '../../atoms/avatar/Avatar'
import { RouteProps } from '../../../../../interfaces/interfaces'
import { SideDrawerProps } from '../../../../../interfaces/ui'

interface NavBarProps {
  routes?: RouteProps[] | []
  onSelect: (x: string) => void
  selectedRoute?: string | undefined
  user?: string
  type?: 'light' | 'dark'
}

export const NavContainer: FC = ({ children }): ReactElement => (
  <nav
    role="navigation"
    id="a11y-menu"
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    {children}
  </nav>
)

export const NavBarDark: FC<NavBarProps> = ({
  routes = [],
  onSelect,
  selectedRoute,
  user,
  type,
}): ReactElement => {
  const dispatch = useDispatch()
  const { userQueue } = useSelector<AppState, Partial<AdminStateProps>>(
    ({ admin }) => admin
  )

  const { state, data } = useSelector<AppState, Partial<SideDrawerProps>>(
    ({
      ui: {
        sideDrawer: { state, data },
      },
    }) => ({
      state,
      data,
    })
  )
  return (
    <div className="neutron-nav neutron-topnav--dark">
      <div className="neutron-left-elements">
        <Link
          style={{ marginRight: '40px' }}
          to={'/'}
          className="neutron-topnav--compact__text neutron-text--white neutron-background—hca-navy neutron-text--product"
          role="menuitem"
        >
          <Image
            src={require('../../../../../assets/rhythm_logo_placeholder.png')}
            orientation={'landscape'}
            height={'40px'}
            width={'150px'}
          />
        </Link>
        {routes.map((link, index) => (
          <Link
            style={{ textDecoration: 'none' }}
            key={link.to}
            to={
              state === 'opened' ? '' : link.redirect ? link.redirect : link.to
            }
          >
            <Tab
              type={type}
              style={{}}
              label={link.label}
              size="large"
              key={index}
              isActive={selectedRoute === link.to}
              onSelect={(): unknown => {
                if (userQueue && userQueue.length > 0 && state === 'opened') {
                  dispatch(
                    displayModal({
                      show: true,
                      type: 'unsavedEdits',
                      confirmation: undefined,
                    })
                  )
                } else if (userQueue && userQueue.length === 0) {
                } else {
                  onSelect(link.to)
                }
                return
              }}
            />
          </Link>
        ))}
      </div>
      <div className="ml-auto neutron-right-elements">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
          }}
        >
          <Link
            to={'/dashboard'}
            className="neutron-topnav--compact__username neutron-background—hca-navy d-inline-block neutron-margin--right-10 neutron-topnav--user-profile-on-dark neutron-text--white"
            role="menuitem"
          >
            {user}
          </Link>
          {/*<AvatarImage src={require('../../../../../assets/avatar-img.jpeg')} />*/}
          {/*<a className="neutron-topnav__notifications-on-dark" href="#!">*/}
          {/*  <i*/}
          {/*    arial-label="notifications"*/}
          {/*    className="material-icons d-inline-block"*/}
          {/*  >*/}
          {/*    notifications*/}
          {/*  </i>*/}
          {/*</a>*/}
        </div>
      </div>
    </div>
  )
}

export const NavBarLight: FC<NavBarProps> = ({
  routes = [],
  onSelect,
  selectedRoute,
}): ReactElement => {
  return (
    <div
      style={{ boxShadow: '0em 5px 5px 1px rgba(31, 37, 50, 0.23)' }}
      className="neutron-nav neutron-nav-light neutron-nav--lower d-xl-inline-flex"
    >
      <div className="neutron-left-elements">
        {routes.map((link, index) => (
          <Link style={{ textDecoration: 'none' }} key={link.to} to={link.to}>
            <Tab
              style={{}}
              label={link.label}
              size="large"
              key={index}
              isActive={selectedRoute === link.to}
              onSelect={(): void => onSelect(link.to)}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}
