import React from 'react'
import { PlaceholderBox } from '../PlaceholderBox/PlaceholderBox'
import { Flex } from 'rebass'

export const TablePlaceholder: ({
  increment,
}: {
  increment: number
}) => any = ({ increment }: { increment: number }) => {
  return (
    <Flex
      className="neutron-right-elements neutron-navbar--search"
      flexDirection="column"
      style={{ width: '100%', display: 'flex', alignItems: 'center' }}
      role="search"
    >
      <PlaceholderBox
        sx={{
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          marginBottom: 1,
          height: 45,
          width: '100%',
          background: '#ddd',
        }}
      />
      {[...Array(increment).keys()].map((val, index) => (
        <PlaceholderBox
          key={'placeholder:table:' + index}
          sx={{
            marginBottom: 1,
            height: 45,
            width: '100%',
            background: '#ddd',
          }}
        />
      ))}
      <PlaceholderBox
        sx={{
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5,
          marginBottom: 1,
          height: 45,
          width: '100%',
          background: '#ddd',
        }}
      />
    </Flex>
  )
}
