import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { Flex } from 'rebass'
import { TRIGGER_MODAL } from '../../../store/constants/actionTypes'
import { Button, Label } from '../NeutronComponents/atoms/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store/rootReducer'
import { AuthStateProps } from '../../../interfaces/auth'
import useEventListener from '@use-it/event-listener'
import { displayModal } from '../../../store/actions/ui'

export const UnsavedEdits: FC = (): ReactElement => {
  const dispatch = useDispatch()
  const { firstName } = useSelector<AppState, Partial<AuthStateProps>>(
    ({ auth: { firstName } }) => ({
      firstName,
    })
  )
  const [backgroundType, setBackgroundType] = useState('panda')

  const getBackgroundType = () => {
    switch (backgroundType) {
      case 'dog':
        return `url(${require('../../../assets/happydoggo.png')})`
      case 'cat':
        return `url(${require('../../../assets/cryingcat.png')})`
      default:
        return `url(${require('../../../assets/woahpanda.png')})`
    }
  }

  useEventListener('mouseover', (e: MouseEvent) => {
    if ((e.target as HTMLElement).className.includes('cat')) {
      setBackgroundType('cat')
    } else if ((e.target as HTMLElement).className.includes('dog')) {
      setBackgroundType('dog')
    } else {
      setBackgroundType('panda')
    }
  })

  return (
    <Flex
      sx={{
        borderRadius: 5,
        boxShadow: '0 2px 7px 4px rgba(0,0,0,0.13)',
        padding: '15px 30px',
        width: 600,
        background: '#fff',
      }}
      flexDirection="column"
    >
      <div
        style={{
          backgroundImage: getBackgroundType(),
          backgroundRepeat: 'no-repeat',
          height: '200px',
          width: '200px',
          backgroundSize: 'cover',
          margin: '20px auto',
          borderRadius: '50%',
          boxShadow: '0 2px 7px 4px rgba(0,0,0,0.13)',
        }}
      />
      <p style={{ fontSize: 20, fontWeight: 'bold' }}>
        Woah! {firstName} it looks like you have some unfinished business!
      </p>
      <p>Are you sure you want leave?</p>
      <Flex justifyContent={'center'} alignItems={'center'}>
        <Button
          className="dog"
          type={'flat'}
          color={'danger'}
          onClick={() =>
            dispatch(
              displayModal({
                show: false,
                type: 'unsavedEdits',
                confirmation: 'stay',
              })
            )
          }
          size={'large'}
        >
          <Label className="dog" value={'Stay'} />
        </Button>
        <Button
          className="cat"
          type={'flat'}
          color={'success'}
          onClick={() => {
            dispatch(
              displayModal({
                show: false,
                type: 'unsavedEdits',
                confirmation: 'leave',
              })
            )
          }}
          size={'large'}
        >
          <Label className="cat" value={'Leave'} />
        </Button>
      </Flex>
    </Flex>
  )
}
