import React, { FC } from 'react'
import { Flex, Text } from 'rebass'
import { Button, Label } from '../Common/NeutronComponents/atoms/button/Button'
import { useHistory } from 'react-router-dom'
import { ADD_NEW_ERROR } from '../../store/constants/actionTypes'
import { useDispatch } from 'react-redux'

interface ErrorPageProps {
  errorStatus: any
}

export const ErrorPage: FC<ErrorPageProps> = ({ errorStatus }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  let pageType
  switch (errorStatus) {
    case '404':
      pageType = <NotFound />
      break
    case '403':
      pageType = <Forbidden />
      break
    case '500':
      pageType = <ServerError />
      break
    case '401':
      pageType = <Unauthorized />
      break
    default:
      pageType = <SomethingWrong />
      break
  }
  return (
    <Flex style={{ height: '100%', width: '100%' }} flexDirection={'row'}>
      <Flex
        style={{
          background: `url(${require('../../assets/Branding_Orange_Style1.png')}) no-repeat center center fixed`,
          height: '100%',
          width: '100%',
          padding: '0 2.983rem',
        }}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {pageType}
        <Button
          type={'raised'}
          color={'primary'}
          onClick={(): unknown => {
            dispatch({ type: ADD_NEW_ERROR, payload: '' })
            history.push('/')
            return
          }}
          size={'large'}
        >
          <Label value={'Return Home'} />
        </Button>
      </Flex>
    </Flex>
  )
}

const SomethingWrong: FC = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text
        sx={{
          paddingLeft: '40px',
          fontSize: '120px',
          marginTop: '-20px',
          color: 'white',
        }}
      >
        Oops
      </Text>
      <Text sx={{ fontSize: '36px', color: 'white', fontWeight: 'bold' }}>
        Something went wrong.
      </Text>
      <Text
        sx={{ padding: '10px 0 0 150px', fontSize: '16px', color: 'white' }}
      >
        Please try again.
      </Text>
    </Flex>
  )
}
const NotFound: FC = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text
        sx={{
          paddingLeft: '30px',
          fontSize: '120px',
          marginTop: '-20px',
          color: 'white',
        }}
      >
        404
      </Text>
      <Text sx={{ fontSize: '36px', color: 'white', fontWeight: 'bold' }}>
        Page Not Found
      </Text>
      <Text
        sx={{ padding: '10px 0 20px 50px', fontSize: '16px', color: 'white' }}
      >
        {"It looks like you're lost."}
      </Text>
    </Flex>
  )
}
const Unauthorized: FC = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text
        sx={{
          paddingLeft: '20px',
          fontSize: '120px',
          marginTop: '-20px',
          color: 'white',
        }}
      >
        401
      </Text>
      <Text sx={{ fontSize: '36px', color: 'white', fontWeight: 'bold' }}>
        Unauthorized
      </Text>
      <Text
        sx={{ padding: '10px 0 20px 40px', fontSize: '16px', color: 'white' }}
      >
        Please request access.
      </Text>
    </Flex>
  )
}
const ServerError: FC = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text
        sx={{
          paddingLeft: '180px',
          fontSize: '120px',
          marginTop: '-20px',
          color: 'white',
        }}
      >
        500
      </Text>
      <Text
        sx={{
          paddingBottom: '10px',
          fontSize: '20px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        The server could not be reached. Try restarting the application
      </Text>
    </Flex>
  )
}
const Forbidden: FC = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text
        sx={{
          paddingLeft: '60px',
          fontSize: '120px',
          marginTop: '-20px',
          color: 'white',
        }}
      >
        403
      </Text>
      <Text
        sx={{
          paddingLeft: '50px',
          fontSize: '36px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Forbidden Page
      </Text>
      <Text sx={{ padding: '10px 0 20px 0', fontSize: '16px', color: 'white' }}>
        {" You don't have permission to access this page."}
      </Text>
    </Flex>
  )
}
