// export a routes config object
import React from 'react'
import { Clipboard } from './components/Admin/Clipboard/Clipboard'
import { Users } from './components/Admin/Users/Users'
import { UnderConstruction } from './components/Common/Under Construction/UnderConstruction'
import { SlidingDrawer } from './components/Common/SlidingDrawer/SlidingDrawer'
import { RouteProps } from './interfaces/interfaces'

interface RoutesProps {
  home: RouteProps[]
  main: RouteProps[]
  admin: RouteProps[]
}

const routes: RoutesProps = {
  home: [
    {
      to: '/',
      label: '',
      approvedRoles: ['Admin', 'base'],
      component: <></>,
      redirect: '/admin/user',
    },
  ],
  main: [
    {
      to: '/dashboard',
      label: 'Dashboard',
      approvedRoles: ['base', 'Admin'],
      component: <UnderConstruction />,
    },
    {
      to: '/all-metrics',
      label: 'All Metrics',
      approvedRoles: ['base', 'Admin'],
      component: <UnderConstruction />,
    },
    {
      to: '/admin',
      label: 'Admin',
      approvedRoles: ['Admin'],
      component: <div></div>,
      redirect: '/admin/user',
    },
  ],
  admin: [
    {
      to: '/admin/user',
      label: 'User',
      approvedRoles: ['Admin'],
      component: (
        <Users>
          <SlidingDrawer />
        </Users>
      ),
    },
    {
      to: '/admin/metric-definition',
      label: 'Metric Definitions',
      approvedRoles: ['Admin'],
      component: <UnderConstruction />,
    },
    {
      to: '/admin/token',
      label: 'Token',
      approvedRoles: ['Admin'],
      component: <Clipboard />,
    },
  ],
}

export default routes
