import React, { FC, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  Button,
  BtnIcon,
} from '../../Common/NeutronComponents/atoms/button/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AuthStateProps } from '../../../interfaces/auth'

export const Clipboard: FC = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth: AuthStateProps = useSelector(({ auth }: any) => auth)
  const { token } = auth
  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="center"
      sx={{
        padding: '.983rem',
      }}
    >
      <Container>
        <CopyToClipboard text={token}>
          <ClipboardIcon>
            <Button
              type="floating-action"
              size="medium"
              color={isCopied ? 'success' : 'primary'}
              onClick={(): unknown => setIsCopied(true)}
            >
              <BtnIcon value={isCopied ? 'done' : 'content_copy'} />
            </Button>
          </ClipboardIcon>
        </CopyToClipboard>
        <Content>{token}</Content>
      </Container>
    </Flex>
  )
}

const Container = styled.div`
  margin-top: 30px;
  width: 600px;
  height: auto;
  border: 1px solid lightgrey;
  border-radius: 5px;
  position: relative;
  overflow-wrap: break-word;
  background: white;
`

const ClipboardIcon = styled.div`
  position: absolute;
  top: -30px;
  right: 10px;
`

const Content = styled.div`
  padding: 15px 20px;
`
