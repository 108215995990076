export const pingConfig = {
  qaOpenIdConnectUrl: 'https://pfqa.idf.medcity.net',
  openIdConnectUrl: 'https://pfqa.idf.medcity.net',
  prodOpenIdConnectUrl: 'https://pf.idf.medcity.net',
  qaClientId: 'RhythmQa',
  clientId: 'RhythmQa',
  prodClientId: 'Rhythm',
  scope: 'rhythm',
  responseType: 'code',
  state: '',
  extras: { accessType: 'offline' },
}

//
