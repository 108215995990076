import React, { ReactElement } from 'react'

interface ImageProps {
  src: string
  overlay?: number
  gradient?: number
  orientation: string
  height: number | string
  width: number | string
  onInteraction?: () => void
}

export const Image: React.FC<ImageProps> = ({
  overlay,
  gradient,
  orientation,
  src,
  height,
  width,
  onInteraction,
}): ReactElement => {
  function getOverlay(overlay: number): string {
    if (overlay % 20 === 0) {
      return `neutron-overlay--${overlay}`
    } else {
      throw new Error('Overlay must be an integer in increments of 20')
    }
  }

  function getGradient(gradient: number): string {
    if (gradient % 20 === 0) {
      return `neutron-overlay--gradient-${gradient}`
    } else {
      throw new Error('Gradient must be an integer in increments of 20')
    }
  }

  return (
    <div
      style={{ height, width }}
      className={`neutron-image--${orientation.toLowerCase() || 'square'}`}
      onClick={(): void => onInteraction && onInteraction()}
    >
      <img src={src} alt="16:9 demo" />
      <div
        className={`${overlay !== undefined ? getOverlay(overlay) : ''} ${
          gradient !== undefined ? getGradient(gradient) : ''
        }`}
      />
    </div>
  )
}
