import React from 'react'

function getButtonType(type: string): string {
  switch (type) {
    case 'flat':
      return ''
    case 'raised':
      return 'neutron-button--raised'
    case 'outline':
      return 'neutron-button--outline'
    case 'floating-action':
      return 'neutron-button--floating-action'
    default:
      return 'neutron-button--raised'
  }
}

interface ButtonProps {
  type: 'flat' | 'raised' | 'floating-action' | 'outline'
  size?: 'small' | 'medium' | 'large'
  color: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'disabled'
  onClick: () => void
  className?: string | undefined
}

interface BtnIconProps {
  value: string
  position?: 'left' | 'right'
}
interface LabelProps {
  value: string | undefined
  className?: string | undefined
}

export const Button: React.FC<Partial<ButtonProps>> = ({
  type = 'raised',
  color = 'primary',
  children,
  size = 'medium',
  onClick,
  className,
}) => {
  return (
    <button
      className={`neutron-button ${className} 
          neutron-button--${color?.toLowerCase()} ${getButtonType(
        type ? type : ''
      )}
      ${size ? 'neutron-button--' + size : ''}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const BtnIcon: React.FC<BtnIconProps> = ({ value, position }) => (
  <span
    className={`material-icons neutron-button__icon neutron-button__icon--${position}`}
  >
    {value}
  </span>
)

export const Label: React.FC<Partial<LabelProps>> = ({ value, className }) => (
  <span className={className || ''}>{value}</span>
)
