import React, { FC, useEffect, useState } from 'react'
import { Table } from '../../Common/NeutronComponents/molecules/table/Table'
import { Box, Flex } from 'rebass'
import {
  Button,
  BtnIcon,
  Label,
} from '../../Common/NeutronComponents/atoms/button/Button'
import { Search } from '../../Common/Search/Search'
import { SnackBar } from '../../Common/SnackBar/SnackBar'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideDrawer } from '../../../store/actions/ui'
import { AppState } from '../../../store/rootReducer'
import { UserDataProps } from '../../../interfaces/user'
import { AdminStateProps } from '../../../store/reducers/admin'
import { TablePlaceholder } from '../../Common/Table/TablePlaceholder'
import {
  USER_NOT_FOUND,
  USER_MATCHES_SEARCH,
} from '../../../store/constants/actionTypes'
import flushSideDrawer from '../../../utils/flushSideDrawer'
import {
  PaginationDisplayCount,
  PaginationPageMenu,
  PaginationRowsPerPage,
  PaginationSet,
} from '../../Common/Pagination/Pagination'
import { AuthStateProps } from '../../../interfaces/auth'
import {
  getAllUsers,
  searchUsers,
  queueUser,
} from '../../../store/actions/user'
import { setSnackBarData } from '../../../store/actions/ui'
import { UIStateProps } from '../../../interfaces/ui'
export const Users: FC = ({ children }) => {
  const dispatch = useDispatch()
  const [direction, setSortDirection] = useState<boolean>(true)
  const [sortKey, setSortKey] = useState<string>('name')
  const [increment, setIncrement] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [searchingUsers, setSearchingUsers] = useState(false)

  const { users, totalUsers, fetching } = useSelector<
    AppState,
    Partial<AdminStateProps>
  >(({ admin: { fetching, users, totalUsers } }) => ({
    fetching,
    users,
    totalUsers,
  }))

  const { token = '' } = useSelector<AppState, Partial<AuthStateProps>>(
    ({ auth: { token } }) => ({
      token,
    })
  )
  const { snackBarData } = useSelector<AppState, Partial<UIStateProps>>(
    ({ ui: { snackBarData } }) => ({
      snackBarData,
    })
  )
  const { sideDrawer, success, userNotFound, userMatchesSearch } = useSelector<
    AppState,
    Partial<UIStateProps>
  >(({ ui: { sideDrawer, success, userNotFound, userMatchesSearch } }) => ({
    sideDrawer,
    success,
    userNotFound,
    userMatchesSearch,
  }))

  useEffect(() => {
    if (success?.deleteUserSuccess === 204) {
      dispatch(
        setSnackBarData({
          type: 'success',
          message: 'User deleted successfully.',
        })
      )
    }
    if (success?.addUserSuccess === 200) {
      dispatch(
        setSnackBarData({
          type: 'success',
          message: 'User created/updated successfully.',
        })
      )
      if (sideDrawer?.state === 'closed' && sideDrawer?.type === 'editUser') {
        if (debouncedSearchTerm) {
          dispatch(
            searchUsers({
              search: debouncedSearchTerm,
              token,
              page: currentPage,
              increment,
            })
          )
        } else {
          dispatch(
            getAllUsers({ token, increment: increment, page: currentPage })
          )
        }
      }
    }
  }, [sideDrawer, success])

  const headers = [
    {
      key: 'name',
      sortable: true,
      sortFunction: (x: string, z: boolean): unknown => {
        setSortDirection(z)
        setSortKey(x)
        setCurrentPage(1)
        dispatch(
          getAllUsers({
            token,
            increment,
            page: 1,
            direction: z ? 'asc' : 'desc',
            key: 'lastName',
          })
        )
        return
      },
      label: 'Name',
      span: 1,
    },
    {
      key: 'hca34',
      sortable: true,
      sortFunction: (x: string, z: boolean): unknown => {
        setSortDirection(z)
        setSortKey(x)
        setCurrentPage(1)
        dispatch(
          getAllUsers({
            token,
            increment,
            page: 1,
            direction: z ? 'asc' : 'desc',
            key: 'hca34',
          })
        )
        return
      },
      label: '3-4 ID',
      span: 1,
    },
    {
      key: 'roleAndOrg',
      sortable: false,
      sortFunction: (): null => null,
      label: 'Organization (Roles)',
      span: 2,
    },
  ]

  useEffect((): void => {
    if (debouncedSearchTerm === '') {
      dispatch(
        getAllUsers({
          token,
          page: currentPage,
          increment,
          direction: direction ? 'asc' : 'desc',
          key: sortKey === 'name' ? 'lastName' : 'hca34',
        })
      )
    } else if (debouncedSearchTerm !== '') {
      dispatch(
        searchUsers({
          search: debouncedSearchTerm,
          token,
          page: 1,
          increment,
        })
      )
    }
    return
  }, [debouncedSearchTerm, currentPage, increment])

  return (
    <Flex
      id="table-wrapper"
      flexDirection={'column'}
      sx={{
        padding: '.983rem',
      }}
    >
      {snackBarData?.message !== '' && (
        <SnackBar
          type={snackBarData?.type || ''}
          message={snackBarData?.message || ''}
        />
      )}
      {children}
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex justifyContent={'flex-start'} alignItems={'center'}>
          <Box sx={{ margin: '10px 20px 0 10px' }}>
            <Search
              onDebounce={(term: string): unknown => {
                if (term !== '') {
                  setSearchingUsers(true)
                }
                if (term === '') {
                  setSearchingUsers(false)
                }
                setDebouncedSearchTerm(term)
                setCurrentPage(1)
                return
              }}
            />
          </Box>
          <Button
            onClick={(): unknown => {
              dispatch(toggleSideDrawer(flushSideDrawer('opened')))
              dispatch(queueUser({ user: [] }))
              return
            }}
            type={'flat'}
            color={'primary'}
          >
            <BtnIcon value={'add_circle'} position={'left'} />
            <Label value="Create New" />
          </Button>
        </Flex>
      </Flex>
      <Box sx={{ marginTop: 20 }}>
        {fetching ? (
          <TablePlaceholder increment={increment} />
        ) : (
          <Table
            headers={headers}
            sortDirection={direction}
            sortKey={sortKey}
            data={users as UserDataProps[]}
          />
        )}
      </Box>
      {totalUsers ? (
        <Flex sx={{ marginTop: 20, height: 100 }} justifyContent={'center'}>
          <PaginationSet>
            <PaginationRowsPerPage
              increments={[10, 30, 60, 90]}
              onSelect={(e: number | string): unknown => {
                if (e === 'All') {
                  setCurrentPage(1)
                  setIncrement(totalUsers)
                }
                if (typeof e === 'number') {
                  setCurrentPage(1)
                  setIncrement(e)
                }
                return
              }}
            />
            <PaginationPageMenu
              pages={
                totalUsers > 10 ? totalUsers / increment : increment / increment
              }
              currentPage={currentPage}
              onSelect={(page: number) => setCurrentPage(page)}
            />
            <PaginationDisplayCount
              increment={increment}
              currentPage={currentPage}
              displayCount={increment * currentPage}
              total={totalUsers}
            />
          </PaginationSet>
        </Flex>
      ) : (
        <></>
      )}
      <Box sx={{ height: 100, width: '100%' }} />
    </Flex>
  )
}
