import React, { ReactElement } from 'react'

const Spinner = ({ square }: { square: string }): ReactElement => {
  const getStroke = (n: string): string => (n === '100px' ? '2' : '6')
  const getColor = (n: string): string => (n === '100px' ? '#0085CA' : '#aaa')
  return (
    <svg
      className="neutron-feedback--spinner"
      width={square}
      height={square}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="neutron-feedback--spinner__fill"
        fill="none"
        stroke={getColor(square)}
        strokeWidth={getStroke(square)}
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  )
}

export const Loading = ({ square }: { square: string }): ReactElement => {
  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner square={square} />
    </div>
  )
}
