import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../NeutronComponents/organisms/nav/NavBar.css'
import {
  NavBarDark,
  NavBarLight,
  NavContainer,
} from '../NeutronComponents/organisms/nav/NavBar'
import { RouteProps } from '../../../interfaces/interfaces'

interface NavigationProps {
  topLevelRoutes: RouteProps[]
  subLevelRoutes?: RouteProps[]
  user?: string
  type?: 'light' | 'dark'
}

export const Navigation: FC<NavigationProps> = ({
  topLevelRoutes,
  subLevelRoutes,
  user,
}) => {
  const [selectedSubLevelRoute, setSelectedSubLevelRoute] = useState(
    '/admin/user'
  )
  const [selectedTopLevelRoute, setSelectedTopLevelRoute] = useState('')

  const location = useLocation()
  useEffect(() => {
    const splitPath = location.pathname.split('/')
    const getRootPath = topLevelRoutes.filter((route: RouteProps) =>
      splitPath.some(path => '/' + path === route.to)
    )
    const path = getRootPath[0]
    setSelectedSubLevelRoute(location.pathname)
    setSelectedTopLevelRoute(path?.to)
  }, [topLevelRoutes, location])

  return (
    <NavContainer>
      <NavBarDark
        onSelect={setSelectedTopLevelRoute}
        selectedRoute={selectedTopLevelRoute}
        routes={topLevelRoutes}
        user={user}
        type={'dark'}
      />
      {(selectedTopLevelRoute || '').includes('/admin') && (
        <NavBarLight
          onSelect={setSelectedSubLevelRoute}
          routes={subLevelRoutes}
          selectedRoute={selectedSubLevelRoute}
        />
      )}
    </NavContainer>
  )
}
