import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import {
  Button,
  BtnIcon,
  Label,
} from '../NeutronComponents/atoms/button/Button'
import {
  SideDrawerProps,
  SlidingDrawerContainerProps,
  UIStateProps,
} from '../../../interfaces/ui'
import { useSelector, useDispatch } from 'react-redux'
import { displayModal, toggleSideDrawer } from '../../../store/actions/ui'
import { CreateUsers } from '../../Admin/Users/CreateUsers'
import { EditUsers } from '../../Admin/Users/EditUsers'
import { AppState } from '../../../store/rootReducer'
import { UserDataProps } from '../../../interfaces/user'
import flushSideDrawer from '../../../utils/flushSideDrawer'
import { queueUser } from '../../../store/actions/user'

export const SlidingDrawer: FC = (): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { state, type = 'createUser', data, updatedRowLocation } = useSelector<
    AppState,
    Partial<SideDrawerProps>
  >(({ ui: { sideDrawer: { state, type, data, updatedRowLocation } } }) => ({
    state,
    type,
    data,
    updatedRowLocation,
  }))
  const { triggerModal } = useSelector<AppState, Partial<UIStateProps>>(
    ({ ui: { triggerModal } }) => ({
      triggerModal,
    })
  )

  const shouldOpen = state === 'opened'

  const mainRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [shouldShowHeader, setShouldShowHeader] = useState(false)

  const {
    firstName,
    lastName,
    hca34,
    organizations,
    name,
    roleAndOrg,
    emailAddress,
  } = data as UserDataProps

  interface SlidingDrawerInsertTypesProps {
    [key: string]: JSX.Element
  }

  const slidingDrawerInsertTypes: SlidingDrawerInsertTypesProps = {
    createUser: <CreateUsers />,
    editUser: (
      <EditUsers
        firstName={firstName}
        lastName={lastName}
        hca34={hca34}
        organizations={organizations}
        name={name}
        roleAndOrg={roleAndOrg}
        emailAddress={emailAddress}
      />
    ),
  }

  useEffect(() => {
    if (state === 'closed') {
      setShouldShowHeader(false)
    }
  }, [state])

  useEffect(() => {
    if (updatedRowLocation && updatedRowLocation > 0) {
      mainRef.current.scrollTo({
        top: updatedRowLocation,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [mainRef, updatedRowLocation])

  function handleScroll(): void {
    if (typeof window !== 'undefined' && mainRef.current.scrollTop) {
      const showHeader = mainRef.current.scrollTop > 50 && state === 'opened'
      setShouldShowHeader(showHeader)
    }
  }

  function scrollToTop(): void {
    if (typeof window !== 'undefined' && mainRef.current.scrollTop) {
      mainRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  return (
    <SlidingDrawerContainer shouldOpen={shouldOpen}>
      {shouldShowHeader && (
        <Position onClick={(): unknown => scrollToTop()}>
          <Button
            type={'flat'}
            size={'medium'}
            color={'primary'}
            onClick={(): unknown => null}
          >
            <BtnIcon value={'keyboard_arrow_up'} />
            <Label value={'Back To Top'} />
          </Button>
        </Position>
      )}
      <Flex
        sx={{
          width: '100%',
          background: 'white',
          height: '100%',
          overflow: 'scroll',
        }}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        ref={mainRef}
        onScroll={(): unknown => handleScroll()}
      >
        <Box sx={{ width: '140px', padding: '0.983rem' }}>
          <Button
            type={'flat'}
            color={'primary'}
            onClick={(): void => {
              if (triggerModal?.type === 'unsavedEdits') {
                dispatch(
                  displayModal({
                    type: 'unsavedEdits',
                    show: true,
                    confirmation: undefined,
                  })
                )
                return
              } else {
                dispatch(queueUser({ user: undefined }))
                history.push('/admin/user')
                dispatch(toggleSideDrawer(flushSideDrawer('closed')))
                return
              }
            }}
          >
            <BtnIcon position={'left'} value={'arrow_back'} />
            <Label value={'Back to User Portal'} />
          </Button>
        </Box>

        <Flex
          flexDirection={'column'}
          sx={{ height: '100%', overflow: 'hidden' }}
        >
          {slidingDrawerInsertTypes[type]}
        </Flex>
      </Flex>
    </SlidingDrawerContainer>
  )
}

const SlidingDrawerContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  z-index: 100;
  position: fixed;
  top: 60px;
  left: ${({ shouldOpen }: SlidingDrawerContainerProps): string =>
    shouldOpen ? '0' : '110vw'};
  opacity: ${({ shouldOpen }: SlidingDrawerContainerProps): string =>
    shouldOpen ? '1' : '0'};
  box-shadow: -1px 8px 7px 2px rgba(0, 0, 0, 0.3);
`

export const Position = styled.div`
  position: fixed;
  z-index: 9999;
  padding: 15px;
  bottom: 35px;
  right: 0.983rem;
`
