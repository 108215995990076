import { SideDrawerProps } from '../interfaces/ui'

export default function(state: 'opened' | 'closed'): Partial<SideDrawerProps> {
  return {
    state,
    type: 'createUser',
    data: {
      firstName: '',
      lastName: '',
      hca34: '',
      name: '',
      roleAndOrg: '',
      organizations: [],
      emailAddress: '',
    },
  }
}
