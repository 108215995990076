import React, { ReactElement, useState, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import routes from './routes'

import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useDispatch } from 'react-redux'
import { Navigation } from './components/Common/Navigation/Navigation'
import { ErrorPage } from './components/Error Page/ErrorPage'
import { Flex } from 'rebass'
import Authentication from './components/Auth/Authentication'

import { AuthStateProps } from './interfaces/auth'

import $ from 'jquery'
import _ from 'lodash'

import './components/Common/NeutronComponents/icons/material-icons/material-icons.css'
import './components/Common/NeutronComponents/css/neutron.css'
import './App.css'
import { AppState } from './store/rootReducer'
import { Loading } from './components/Common/Loading/Loading'
import { UIStateProps } from './interfaces/ui'
import { Modal } from './components/Common/Modal/Modal'

const browserHistory = createBrowserHistory({ basename: '' })

// add jquery globally
// needed by appauth js
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any
  }
}
window.$ = $

const App = (): ReactElement => {
  const {
    token = '',
    firstName,
    lastName,
    authorizedOrganizations,
    fetchingAuthUser,
  } = useSelector<AppState, Partial<AuthStateProps>>(
    ({
      auth: {
        fetchingAuthUser,
        token,
        firstName,
        lastName,
        authorizedOrganizations,
      },
    }) => ({
      token,
      firstName,
      lastName,
      authorizedOrganizations,
      fetchingAuthUser,
    })
  )

  const isAuthenticated = (token: string): boolean => Boolean(token)

  const { error, triggerModal } = useSelector<AppState, Partial<UIStateProps>>(
    ({ ui: { triggerModal, error } }) => ({
      error,
      triggerModal,
    })
  )
  // goes through all userAuthorizations and extracts the permissions array into one array of uniq roles
  const filteredRoles = _.uniq(
    authorizedOrganizations
      ?.map(authorization => {
        return [...authorization?.permissions]
      })
      .flat()
  )

  const user: { name: string; authRoles: string[] } = {
    name: firstName + ' ' + lastName,
    authRoles: filteredRoles,
  }

  const { main, admin } = routes

  const topLevelRoutesAllowed = main.filter(route => {
    return route.approvedRoles.some(role =>
      user.authRoles.some(auth => auth.toLowerCase() === role.toLowerCase())
    )
  })
  const subLevelRoutesAllowed = [...admin].filter(route => {
    return route.approvedRoles.some(role =>
      user.authRoles.some(auth => auth.toLowerCase() === role.toLowerCase())
    )
  })

  return (
    <Authentication>
      {isAuthenticated(token) && (
        <div
          style={{
            margin: 0,
            padding: 0,
            overflowX: 'hidden',
            height: '100%',
          }}
        >
          {triggerModal?.show && <Modal type={triggerModal?.type} />}
          <Router history={browserHistory}>
            <Navigation
              topLevelRoutes={topLevelRoutesAllowed}
              subLevelRoutes={subLevelRoutesAllowed}
              user={user.name}
            />
            <Flex
              sx={{
                flexDirection: 'column',
                height: 'calc(100vh - 60px)',
                overflowY: 'scroll',
                overflowX: 'hidden',
                position: 'relative',
              }}
            >
              {error !== '' ? (
                <ErrorPage errorStatus={error || ''} />
              ) : (
                <Switch>
                  {isAuthenticated(token) && !fetchingAuthUser ? (
                    [...subLevelRoutesAllowed, ...topLevelRoutesAllowed].map(
                      route => {
                        return (
                          <Route
                            path={route.to}
                            key={route.to + route.label}
                            render={(): JSX.Element => {
                              const authorized: boolean = topLevelRoutesAllowed.some(
                                ({ approvedRoles }): boolean =>
                                  approvedRoles.some((role): boolean =>
                                    user.authRoles.some(
                                      auth =>
                                        auth.toLowerCase() ===
                                        role.toLowerCase()
                                    )
                                  )
                              )

                              if (route.redirect) {
                                return <Redirect to={route.redirect} />
                              }

                              if (authorized) {
                                return route.component
                              } else {
                                return <ErrorPage errorStatus={'403'} />
                              }
                            }}
                          />
                        )
                      }
                    )
                  ) : (
                    <Loading square={'40px'} />
                  )}
                  <Route path="/redirect">
                    <Redirect to="/" />
                  </Route>
                  <Route
                    // exact
                    path="/"
                    render={(): ReactNode => {
                      if (
                        user.authRoles.some(
                          role => role.toLowerCase() === 'admin'
                        )
                      ) {
                        return <Redirect to={'/admin/users'} />
                      } else {
                        return (
                          <Redirect to={topLevelRoutesAllowed[0]?.to || ''} />
                        )
                      }
                    }}
                  />
                </Switch>
              )}
            </Flex>
          </Router>
        </div>
      )}
    </Authentication>
  )
}
export default App
