import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Flex } from 'rebass'
import './Dropdown.css'
import { Chip } from '../NeutronComponents/atoms/chip/Chip'
import useEventListener from '@use-it/event-listener'
import { setSnackBarData } from '../../../store/actions/ui'
import { useDispatch } from 'react-redux'

interface MultiSelectDropdownProps {
  selections: string[]
  options: string[]
  isValid: boolean
  disabled: boolean
  id: string
  updateDropdownList: (x: string[]) => void
}

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  selections,
  options,
  updateDropdownList,
  disabled,
  isValid,
  id,
}): ReactElement => {
  const [showHide, setShowHide] = useState<boolean>(false)

  const [optionsListTop, setOptionsListTop] = useState<number>(50)
  const mainRef = useRef() as React.MutableRefObject<HTMLInputElement>
  useEffect(() => {
    const { height } = mainRef?.current?.getBoundingClientRect() || 50
    setOptionsListTop(height + 10)
  }, [selections])

  const dispatch = useDispatch()

  useEventListener('click', (e: MouseEvent) => {
    if ((e.target as HTMLElement).id === id + ':multiSelectCaret') {
      setShowHide(!showHide)
      return
    }
    if ((e.target as HTMLElement).id !== id + ':multiselectChild') {
      if (selections.length === 0) {
        dispatch(
          setSnackBarData({
            type: 'error',
            message: 'Cannot save empty role field.',
          })
        )
        updateDropdownList(['User'])
        setShowHide(false)
      } else {
        setShowHide(false)
      }
    }
  })

  return (
    <div
      id="multiselectParent"
      onClick={(): unknown => (!disabled ? setShowHide(!showHide) : '')}
    >
      <Flex
        flexDirection="row"
        className={`${disabled ? 'disabled' : ''} `}
        style={{
          width: 200,
          minHeight: 40,
          position: 'relative',
          border: `1px solid ${
            showHide ? '#32a1ce' : isValid ? '#ddd' : 'red'
          }`,
          marginBottom: 10,
          borderRadius: 5,
          padding: '5px 15px',
        }}
        ref={mainRef}
      >
        <Flex
          justifyContent={'flex-start'}
          alignItems={'center'}
          style={{
            flex: '1 1 150px',
            backgroundColor: disabled ? '#bcbcbc' : '#FFF',
            width: 170,
            flexWrap: 'wrap',
          }}
          id={id + ':multiselectChild'}
        >
          {selections.map(p => (
            <Chip key={p} color={disabled ? 'disabled' : 'primary'} value={p}>
              <i
                className="material-icons"
                style={{
                  marginLeft: 5,
                  cursor: !disabled ? 'pointer' : 'not-allowed',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
                onClick={(): unknown => {
                  if (!disabled) {
                    updateDropdownList([
                      ...selections.filter(someOption => p !== someOption),
                    ])
                  }
                  return
                }}
              >
                close
              </i>
            </Chip>
          ))}
        </Flex>
        {showHide && options.length > 0 && (
          <div
            style={{
              top: optionsListTop,
              left: 0,
              width: '100%',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '5px',
              height: 'max-content',
              maxHeight: 200,
              overflowY: 'scroll',
              zIndex: 99,
              boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.21)',
            }}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: '10px',
                      cursor: disabled ? 'not-allowed' : 'pointer',
                      fontFamily: 'sans-serif',
                      boxSizing: 'border-box',
                      textAlign: 'left',
                      borderBottom: '0.5px solid rgba(0, 0, 0, 0.3)',
                    }}
                    onClick={(e): unknown => {
                      e.stopPropagation()
                      // this is the option window we will update the list with
                      // when a selection is made, we should drop selection from options
                      if (selections.some(selection => selection === option)) {
                        return
                      } else {
                        updateDropdownList([...selections, option])
                        return
                      }
                    }}
                  >
                    {option}
                  </div>
                )
              })}
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            right: 0,
            marginTop: '2px',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <i
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            className="material-icons"
            id={id + ':multiSelectCaret'}
          >
            {!showHide || options.length === 0
              ? 'arrow_drop_down'
              : 'arrow_drop_up'}
          </i>
        </div>
      </Flex>
    </div>
  )
}
