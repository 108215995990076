import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Flex, Text } from 'rebass'
import { MultiSelectDropdown } from '../../Common/Dropdown/MultiSelectDropdown'
import {
  Button,
  BtnIcon,
} from '../../Common/NeutronComponents/atoms/button/Button'
import { Input } from '../../Common/NeutronComponents/atoms/input/Input'
import {
  RowDataProps,
  ADUserDataProps,
  UserInputRowProps,
} from '../../../interfaces/user'
import { useDispatch, useSelector } from 'react-redux'
import { SideDrawerProps, UIStateProps } from '../../../interfaces/ui'
import { AppState } from '../../../store/rootReducer'
import { useDebounce } from '../../../utils/useDebounce'
import { verifyUser34 } from '../../../store/actions/user'
import { AuthStateProps } from '../../../interfaces/auth'
import { Loading } from '../../Common/Loading/Loading'
import { SingleSelectDropdown } from '../../Common/Dropdown/SingleSelectDropdown'
import _ from 'lodash'
import { DropDownObjectProps } from '../../../interfaces/interfaces'
import { displayModal } from '../../../store/actions/ui'

export const UserInputRow: FC<UserInputRowProps> = ({
  editingUser,
  clearInput,
  addRow,
  ableToAdd,
  ableToEdit,
  rowIndex,
  deleteRow,
  saveEdits,
  editRow,
  rowData,
}): ReactElement => {
  const dispatch = useDispatch()
  const { type = 'blank' } = useSelector<AppState, Partial<SideDrawerProps>>(
    ({
      ui: {
        sideDrawer: { state, type, data },
      },
    }) => ({
      state,
      type,
      data,
    })
  )
  const { authorizedOrganizations, token, options } = useSelector<
    AppState,
    Partial<AuthStateProps>
  >(({ auth: { token, authorizedOrganizations, options } }) => ({
    authorizedOrganizations,
    token,
    options,
  }))

  const { triggerModal } = useSelector<AppState, Partial<UIStateProps>>(
    ({ ui: { triggerModal } }) => ({
      triggerModal,
    })
  )

  const organizationLevels: DropDownObjectProps[] = _.uniqBy(
    authorizedOrganizations?.map(
      (authorization): DropDownObjectProps => {
        return {
          text: authorization?.organization?.typeName || '',
          value: authorization?.organization?.typeName || '',
          id: authorization?.organization?.typeId || '',
        }
      }
    ),
    'text'
  )

  const determineDataSourceForOptions = (
    optionType: string,
    optionSource: DropDownObjectProps[]
  ) => {
    if (rowData) {
      if (optionType === 'levels') {
        return optionSource.filter(
          option => option.text === rowData.organizationLevel
        )[0]
      }
      if (optionType === 'organization') {
        return rowData.organizationId
      }
    } else {
      if (optionType === 'levels' || 'organization') {
        return { text: 'Select one', value: 'Select one', id: '1' }
      } else {
        return optionSource[0]
      }
    }
  }

  const [hca34, setHca34] = useState<string>((rowData && rowData?.hca34) || '')
  const [organizationLevel, setOrganizationLevel] = useState(
    determineDataSourceForOptions('levels', organizationLevels)
  )

  const [organizationList, setOrganizationList] = useState<
    DropDownObjectProps[] | undefined
  >(options && options[organizationLevel?.text || ''])

  const [organizationObj, setOrganizationObj] = useState<
    DropDownObjectProps | undefined
  >(determineDataSourceForOptions('organization', organizationList || []))

  useEffect(() => {
    if (options) {
      const list = options && options[organizationLevel?.text || '']
      setOrganizationList(list)
      if ((!rowData && list) || (rowData && ableToEdit && !ableToAdd && list)) {
        setOrganizationObj(list[0])
      }
    }
  }, [organizationLevel])

  const roleList = ['Admin', 'User', 'CNC']
  const [roles, setRoles] = useState<string[]>(
    (rowData && rowData?.roles) || [roleList.filter(role => role === 'User')[0]]
  )
  const [isInvalid, setIsInvalid] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [results, setResults] = useState<Partial<ADUserDataProps>>({})
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  const filteredRoleOptions = roleList.filter(
    opt => !roles.some(role => role === opt)
  )

  const debouncedSearchTerm = useDebounce(hca34, 750)

  useEffect(() => {
    const verifyUserInAD = async () => {
      if (hca34 && token && ableToAdd) {
        setIsSearching(true)
        const adResults = await verifyUser34({ hca34, token })

        if (adResults === 'userExists') {
          setErrorMessage('User already exists.')
          setIsInvalid([...isInvalid, 'hca34'])
          setIsSearching(false)
          return
        }

        if (adResults && Object.keys(adResults).length > 0) {
          setResults({
            firstName: adResults.firstName || '',
            lastName: adResults.lastName || '',
            hca34: hca34,
            emailAddress: adResults.emailAddress || '',
          })
          setIsSearching(false)
          adResults?.firstName
            ? setIsInvalid([...isInvalid.filter(key => key !== 'hca34')])
            : setIsInvalid([...isInvalid, 'hca34'])
        } else {
          setErrorMessage('User not found.')
          setIsInvalid([...isInvalid, 'hca34'])
          setIsSearching(false)
        }
      } else {
        setResults({})
        setIsSearching(false)
      }
    }
    verifyUserInAD()
  }, [debouncedSearchTerm])

  const creatingUser = type === 'createUser'

  const disabled = ableToAdd || ableToEdit
  let buttonType = ''

  if (rowData && !ableToEdit) {
    buttonType = 'canEdit'
  } else if (rowData && ableToEdit && !ableToAdd) {
    buttonType = 'editing'
  } else if (ableToAdd) {
    buttonType = 'adding'
  }

  interface InvalidFieldsProps {
    [key: string]: string | string[] | undefined
  }

  const validateFields = (
    fields: InvalidFieldsProps
  ): Partial<RowDataProps> | null => {
    const listOfInvalids = Object.keys(fields).filter((key: string) => {
      if (key === 'hca34') {
        if (!editingUser && Object.keys(results).length === 0) {
          return true
        } else if (editingUser) {
          return !fields[key]
        }
      } else if (key === 'roles' && fields?.roles?.length === 0) {
        return true
      } else if (
        (key === 'organizationLevel' &&
          fields?.organizationLevel === 'Select one') ||
        (key === 'organizationId' && fields?.organizationId === 'Select one')
      ) {
        return true
      } else {
        return !fields[key]
      }
    })

    if (listOfInvalids.length === 0) {
      setIsInvalid([])
      return fields
    } else {
      setIsInvalid(listOfInvalids)
      return null
    }
  }

  const getButtonType = (x: string): ReactElement => {
    switch (x) {
      case 'adding':
        return (
          <Button
            color={'success'}
            type={'raised'}
            size={'medium'}
            onClick={(): unknown => {
              const validatedFields = validateFields({
                hca34: editingUser ? editingUser : hca34,
                organizationId: organizationObj?.text,
                organizationLevel: organizationLevel?.text,
                roles,
              })

              if (validatedFields !== null) {
                addRow &&
                  addRow({
                    hca34,
                    roles,
                    organizationLevel: organizationLevel?.text,
                    organizationId: organizationObj,
                    ...results,
                  })
                setRoles(['User'])
              } else {
                return null
              }
            }}
          >
            <BtnIcon value={'add'} />
          </Button>
        )
      case 'editing':
        return (
          <Button
            color={'primary'}
            type={'flat'}
            size={'medium'}
            onClick={(): unknown => {
              const row = {
                row: {
                  ...validateFields({
                    organizationId: organizationObj?.text,
                    organizationLevel: organizationLevel?.text,
                    roles,
                  }),
                },
                index: rowIndex,
              }

              if (!row.row.hasOwnProperty('roles')) {
                return
              } else {
                return (
                  saveEdits &&
                  saveEdits({
                    hca34,
                    emailAddress: rowData?.emailAddress,
                    firstName: rowData?.firstName,
                    lastName: rowData?.lastName,
                    roles,
                    organizationLevel: organizationLevel?.text,
                    organizationId: organizationObj,
                  })
                )
              }
            }}
          >
            <BtnIcon value={'done'} />
          </Button>
        )
      case 'canEdit':
        return (
          <>
            <Button
              color={'danger'}
              type={'flat'}
              size={'medium'}
              onClick={(): unknown => {
                deleteRow && deleteRow(rowIndex)
                dispatch(
                  displayModal({
                    show: true,
                    type: 'removeRow',
                    confirmation: undefined,
                  })
                )
                return
              }}
            >
              <BtnIcon value={'remove'} />
            </Button>
            <Button
              color={'primary'}
              type={'flat'}
              size={'medium'}
              onClick={(): unknown => {
                editRow && editRow(rowIndex)
                return
              }}
            >
              <BtnIcon value={'edit'} />
            </Button>
          </>
        )
      default:
        return <></>
    }
  }
  return (
    <Flex
      sx={{ width: '100%' }}
      flexDirection={['column', 'column', 'row']}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      {creatingUser && (
        <Flex flexDirection="column" sx={{ margin: '0px 10px 0 0' }}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>User 3-4* </Text>
            {!isSearching &&
            isInvalid.some((key: string) => key === 'hca34') ? (
              <span className={'neutron-text--support danger'}>
                <small className="neutron-text--american-rose">
                  {errorMessage}
                </small>
              </span>
            ) : (
              <span className={'neutron-text--support danger'}>
                <small className="neutron-text--dusty-green">
                  {Object.keys(results).length > 0 && results?.firstName
                    ? results?.lastName + ', ' + results?.firstName
                    : ''}
                </small>
              </span>
            )}
            {isSearching && (
              <div>
                <Loading square={'20px'} />
              </div>
            )}
          </Flex>
          <Input
            isValid={!isInvalid.some((key: string) => key === 'hca34')}
            clearInput={clearInput}
            defaultValue={clearInput ? '' : rowData?.hca34 || ''}
            disabled={!disabled}
            captureInput={(input: string): unknown => setHca34(input)}
          />
        </Flex>
      )}
      <Flex flexDirection="column" sx={{ margin: '0px 10px 0 0' }}>
        <Text>Organization Level*</Text>
        <SingleSelectDropdown
          id={`${rowData?.hca34 || 'basic'}:${rowData?.organizationLevel ||
            'basic'}:A`}
          isValid={!isInvalid.some(key => key === 'organizationLevel')}
          disabled={!disabled}
          options={organizationLevels}
          selection={organizationLevel}
          selectOption={setOrganizationLevel}
        />
      </Flex>
      <Flex flexDirection="column" sx={{ margin: '0px 10px 0 0' }}>
        <Text>Organization*</Text>
        <SingleSelectDropdown
          id={`${rowData?.hca34 || 'basic'}:${rowData?.organizationLevel ||
            'basic'}:B`}
          isValid={!isInvalid.some(key => key === 'organizationId')}
          disabled={!disabled}
          options={organizationList}
          selection={organizationObj}
          selectOption={setOrganizationObj}
        />
      </Flex>
      <Flex flexDirection="column" sx={{ margin: '0px 10px 0 0' }}>
        <Text>Roles*</Text>
        <div>
          <MultiSelectDropdown
            id={
              (rowData &&
                (rowData?.hca34 || '') + (rowData?.organizationLevel || '')) ||
              ''
            }
            isValid={!isInvalid.some(key => key === 'roles')}
            disabled={!disabled}
            selections={roles}
            options={filteredRoleOptions}
            updateDropdownList={setRoles}
          />
        </div>
      </Flex>
      <Flex sx={{ flex: '1 1 200px', margin: '12px 0 0 10px' }}>
        {getButtonType(buttonType)}
      </Flex>
    </Flex>
  )
}
