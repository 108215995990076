const environment = process.env.REACT_APP_ENV

let _serverUrl = null
let _type = null

if (environment) {
  _type = environment.toLowerCase()
  switch (_type) {
    case 'dev':
      _serverUrl = 'https://rhythm-dev.hcahealthcare.cloud/api/'
      break
    case 'local':
      _serverUrl = 'https://rhythm-dev.hcahealthcare.cloud/api/'
      break

    case 'qa':
      _serverUrl = 'https://rhythm-qa.hcahealthcare.cloud/api/'
      break

    case 'prod':
      _serverUrl = 'https://rhythm.hcahealthcare.cloud/api/'
      break

    default:
      _serverUrl = 'https://rhythm-dev.hcahealthcare.cloud/api/'
  }
} else {
  throw Error('App environment variable is not set')
}

export default { _serverUrl, _type }
