import {
  FETCHING,
  GET_AUTH_USER,
  ADD_NEW_ERROR,
  FETCHING_AUTH_USER,
} from '../constants/actionTypes'
import { Action } from 'redux'
import environment from '../../environment'
import { ThunkDispatch } from 'redux-thunk'

import { UserAuthorizationsProps, UserDataProps } from '../../interfaces/user'
import { DropDownObjectProps } from '../../interfaces/interfaces'

const serverUrl = environment._serverUrl

function mapRoles(role: string): string {
  switch (role) {
    case 'base':
      return 'Admin'
    default:
      return 'Admin'
  }
}

export const getAuthenticatedUser = ({
  hca34,
  token,
}: {
  hca34: string
  token: string
}): ((
  dispatch: ThunkDispatch<UserDataProps, unknown, Action>
) => Promise<void>) => async (
  dispatch: ThunkDispatch<UserDataProps, unknown, Action>
): Promise<void> => {
  dispatch({ type: FETCHING_AUTH_USER, payload: true })
  try {
    // TODO: swap JSON grabs for fetch calls
    const getUser = await fetch(serverUrl + `users/${hca34}/profile/flat`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      referrerPolicy: 'no-referrer',
    })

    const status = await getUser.status
    if (status === 200) {
      const user = await getUser.json()
      const organizationLevels = user.userAuthorizations.filter(
        ({ organization, permissions, roles }: UserAuthorizationsProps) =>
          permissions?.some(role => mapRoles(role) === 'Admin')
      )

      // const organizationLevels = ['Company', 'Group', 'Division', 'Facility']
      const getOptions = (list: UserAuthorizationsProps[], level: number) =>
        list
          .filter(
            ({ organization }: Partial<UserAuthorizationsProps>) =>
              organization?.level === level
          )
          ?.map(
            ({
              organization,
            }: Partial<UserAuthorizationsProps>): DropDownObjectProps =>
              ({
                text: organization?.name,
                id: organization?.orgId,
                value: organization?.name,
              } as DropDownObjectProps)
          )
          .sort((a, b): number => {
            if (a.text !== undefined && b.text !== undefined) {
              if (a?.text > b?.text) {
                return 1
              }
              if (a?.text < b?.text) {
                return -1
              }
            }
            return 0
          })

      const options = {
        Company: getOptions(organizationLevels, 0),
        Group: getOptions(organizationLevels, 1),
        Division: getOptions(organizationLevels, 2),
        Facility: getOptions(organizationLevels, 3),
      }
      dispatch({
        type: GET_AUTH_USER,
        payload: {
          ...user,
          options,
          authorizedOrganizations: organizationLevels,
        },
      })
    } else {
      dispatch({ type: ADD_NEW_ERROR, payload: status.toString() })
    }
  } catch (ex) {
    dispatch({ type: ADD_NEW_ERROR, payload: ex })
  } finally {
    dispatch({ type: FETCHING_AUTH_USER, payload: false })
  }
}
