import { combineReducers } from 'redux'
import admin from './reducers/admin'
import auth from './reducers/auth'
import ui from './reducers/ui'

// combine reducers does not persist the key/namespaces
const rootReducer = combineReducers({
  admin,
  auth,
  ui,
})
export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
