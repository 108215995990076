import React, { FC } from 'react'
import { Flex } from 'rebass'

export const SnackBar: FC<{ message: string; type: string }> = ({
  message,
  type,
}) => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      className={`neutron-snackbar neutron-snackbar--${type}`}
      style={{
        position: 'absolute',
        zIndex: 9999,
        top: 30,
        right: 50,
        height: 40,
      }}
    >
      <i className="material-icons">
        {type === 'success' ? 'check_circle' : type}
      </i>
      <span style={{ marginLeft: 15 }} className="neutron-snackbar__text">
        {message}
      </span>
      {/* <a className="neutron-snackbar__action">action</a> */}
    </Flex>
  )
}
