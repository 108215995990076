import React, { FC, ReactElement } from 'react'
import { Flex, Text } from 'rebass'
import { Button, Label } from '../NeutronComponents/atoms/button/Button'
import { useHistory } from 'react-router-dom'

export const UnderConstruction: FC = (): ReactElement => {
  const randomNumber = Math.floor(Math.random() * 2)
  const pageType = [<LightBG key={'lightness'} />, <DarkBG key={'dawkness'} />]

  return (
    <Flex style={{ height: '100%', width: '100%' }} flexDirection={'row'}>
      {pageType[randomNumber]}
    </Flex>
  )
}

const LightBG: FC = () => {
  const history = useHistory()
  return (
    <Flex
      style={{
        background: `url(${require('../../../assets/UnderConstruction_2_bg.jpg')}) no-repeat center center fixed`,
        width: '100%',
        height: '100%',
        padding: '0 2.983rem',
      }}
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'center'}
    >
      <Flex flexDirection={'column'}>
        <Text sx={{ fontSize: '38px' }}>Under Construction </Text>
        <Text sx={{ fontSize: '65px', marginTop: '-20px', fontWeight: 'bold' }}>
          Coming soon.
        </Text>
      </Flex>
      <Button
        type={'raised'}
        color={'primary'}
        onClick={(): unknown => history.push('/')}
        size={'large'}
      >
        <Label value={'Return Home'} />
      </Button>
    </Flex>
  )
}

const DarkBG: FC = () => {
  const history = useHistory()
  return (
    <Flex
      style={{
        background: `url(${require('../../../assets/UnderConstruction_bg.jpg')}) no-repeat center center fixed`,
        height: '100%',
        width: '100%',
      }}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Flex flexDirection={'column'}>
        <Text sx={{ fontSize: '38px', color: 'white', fontWeight: 600 }}>
          Under Construction{' '}
        </Text>
        <Text sx={{ fontSize: '90px', color: 'white', marginTop: '-30px' }}>
          Coming soon.
        </Text>
      </Flex>
      <Button
        type={'raised'}
        color={'primary'}
        onClick={(): unknown => history.push('/')}
        size={'large'}
      >
        <Label value={'Return Home'} />
      </Button>
    </Flex>
  )
}
