import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Flex } from 'rebass'
import { DropDownObjectProps } from '../../../interfaces/interfaces'
import './Dropdown.css'
import useEventListener from '@use-it/event-listener'

interface SingleSelectDropdownProps {
  selection: DropDownObjectProps | undefined
  options: DropDownObjectProps[] | undefined
  isValid: boolean
  disabled: boolean
  selectOption: (x: DropDownObjectProps) => void
  updateDropdownList?: (x: string[]) => void
  id: string | undefined
  tabbing?: number | undefined
}

export const SingleSelectDropdown: FC<SingleSelectDropdownProps> = ({
  options,
  disabled,
  isValid,
  selection,
  selectOption,
  id,
  tabbing,
}): ReactElement => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [showHide, setShowHide] = useState<boolean>(false)
  const [userInput, setUserInput] = useState<string | undefined>(
    selection?.text || ''
  )
  const [filteredOptions, setFilteredOptions] = useState<DropDownObjectProps[]>(
    options || []
  )

  useEffect(() => {
    setUserInput(selection?.text)
  }, [selection])

  useEffect(() => {
    if (!options) return
    if (userInput === '') {
      setFilteredOptions(options)
    } else if (userInput) {
      setFilteredOptions([
        ...options.filter(
          option =>
            option?.text &&
            option?.text.toLowerCase().includes(userInput?.toLowerCase())
        ),
        ...options.filter(
          option =>
            option?.text &&
            !option?.text.toLowerCase().includes(userInput.toLowerCase())
        ),
      ])
    }
  }, [userInput, selection])

  useEventListener('click', (e: MouseEvent) => {
    if ((e.target as HTMLElement).id === id + ':singleSelect') {
      setShowHide(!showHide)
      return
    } else if ((e.target as HTMLElement).id !== id) {
      if (options && userInput === '') {
        selectOption(options[0])
        setUserInput(options[0].text)
        setShowHide(false)
      } else {
        setShowHide(false)
      }
    }
  })

  return (
    <Flex
      flexDirection="row"
      style={{
        position: 'relative',
      }}
    >
      <form
        className={`neutron-input ${
          disabled ? 'neutron-input--is-disabled' : ''
        } ${!isValid && !disabled ? 'neutron-input--has-error' : ''}`}
        style={{
          display: 'flex',
          position: 'relative',
        }}
      >
        <input
          id={id}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          value={userInput}
          className="neutron-input__text-field"
          type="text"
          autoComplete="off"
          aria-disabled={disabled}
          disabled={disabled}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
            setUserInput(ev.target.value)
          }}
          onFocus={(): unknown => {
            if (!showHide) {
              setShowHide(true)
            }
            return
          }}
        />
        <div
          style={{
            position: 'absolute',
            right: 0,
            padding: '7px 0 0 0',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (disabled) return
            setShowHide(!showHide)
          }}
        >
          <i
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            id={id + ':singleSelect'}
            className="material-icons"
          >
            {!showHide ? 'arrow_drop_down' : 'arrow_drop_up'}
          </i>
        </div>
      </form>
      {showHide && (
        <div
          className="dropdown-noScrollbar"
          style={{
            width: '100%',
            top: '50px',
            height: 'max-content',
            maxHeight: '200px',
            backgroundColor: 'white',
            position: 'absolute',
            borderRadius: '5px',
            zIndex: 99,
            overflowY: 'scroll',
            boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              height: 'max-content',
              maxHeight: '200px',
              width: '210px',
            }}
          >
            <div className="dropdownList">
              {filteredOptions &&
                filteredOptions?.map(
                  (option: DropDownObjectProps | undefined, index) => {
                    return (
                      <div
                        tabIndex={currentTab}
                        key={index}
                        style={{
                          padding: '10px',
                          cursor: disabled ? 'not-allowed' : 'pointer',
                          fontFamily: 'sans-serif',
                          boxSizing: 'border-box',
                          textAlign: 'left',
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.3)',
                        }}
                        onClick={(e): unknown => {
                          if (option) {
                            e.stopPropagation()
                            setUserInput(option?.text || '')
                            selectOption && selectOption(option)
                            setShowHide(false)
                          }
                          return
                        }}
                        onKeyDown={(e): unknown => {
                          const keyCode = e.keyCode
                          if (option && keyCode === 13) {
                            e.stopPropagation()
                            setUserInput(option?.text || '')
                            selectOption && selectOption(option)
                            setShowHide(false)
                          }
                          return
                        }}
                      >
                        {option?.text}
                      </div>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      )}
    </Flex>
  )
}
