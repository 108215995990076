import React, { FC, ReactElement, useEffect, useState } from 'react'
import './Pagination.css'
import { Flex } from 'rebass'

export const PaginationSet: FC = ({ children }) => (
  <Flex
    justifyContent={'space-around'}
    alignItems={'center'}
    className="neutron-pagination__set"
  >
    {children}
  </Flex>
)

interface PaginationRowsPerPageProps {
  increments: number[]
  onSelect: (x: number | string) => void
}

export const PaginationRowsPerPage: FC<PaginationRowsPerPageProps> = ({
  increments = [10, 20, 25],
  onSelect,
}) => {
  const allIntervals = [...increments, 'All']
  return (
    <div className="neutron-pagination__rows-per-page">
      <p>Rows per page:</p>
      <div className="neutron-dropdown--simple ">
        <select
          className="neutron-dropdown__content"
          onChange={e => {
            e.persist()
            onSelect(allIntervals[parseInt(e.target.value)])
          }}
        >
          {allIntervals.map((interval, index) => (
            <option key={interval} value={index}>
              {interval || ''}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

interface PaginationDisplayCountProps {
  displayCount: number
  total: number
  increment: number
  currentPage: number
}

export const PaginationDisplayCount: FC<PaginationDisplayCountProps> = ({
  displayCount = 10,
  total = 0,
  increment,
  currentPage,
}) => {
  const start = increment * (currentPage - 1) + 1
  const end = increment * currentPage
  return (
    <div className="neutron-pagination__display-count">
      <p>
        {start}-{end} of {total}
      </p>
    </div>
  )
}
interface PaginationPageMenuProps {
  pages: number
  currentPage: number | 1
  onSelect: (x: number) => void
}

export const PaginationPageMenu: ({
  currentPage,
  pages,
  onSelect,
}: PaginationPageMenuProps) => React.ReactElement = ({
  currentPage,
  pages,
  onSelect,
}: PaginationPageMenuProps): ReactElement => {
  interface GroupProps {
    top: number
    bottom: number
  }

  const [grouping, setGrouping] = useState<GroupProps>({ bottom: 1, top: 5 })
  const roundedPages = Math.ceil(pages)

  useEffect(() => {
    if (currentPage === 1) {
      setGrouping({ bottom: 1, top: 5 })
    }
    if (!(currentPage >= grouping.top && currentPage <= grouping.bottom)) {
      const nearestTop = Math.ceil(currentPage / 5) * 5
      setGrouping({ top: nearestTop, bottom: nearestTop - 5 })
    }
  }, [currentPage])
  return (
    <nav
      className="neutron-pagination"
      id="pageNumbers"
      role="navigation"
      aria-label="Pagination"
    >
      <ul className="row">
        <li
          onClick={() => {
            if (currentPage === 1) return
            if (currentPage > 1) {
              onSelect(currentPage - 1)
            }

            if (currentPage - 1 < grouping.bottom) {
              setGrouping({ bottom: currentPage - 5, top: currentPage - 1 })
            }
          }}
        >
          <a href="#" className="neutron-pagination__prev">
            <i className="material-icons">chevron_left</i>
          </a>
        </li>
        {currentPage > 5 && (
          <>
            <li
              onClick={() => {
                setGrouping({ bottom: 1, top: 5 })
                onSelect(1)
              }}
            >
              <a
                className="neutron-pagination__page"
                aria-label="Go to Page 1"
                href="#"
              >
                1
              </a>
            </li>
            <li>
              <i className="material-icons neutron-pagination__overflow">
                more_horiz
              </i>
            </li>
          </>
        )}
        {[...Array(roundedPages).keys()].map(val => {
          const pageDisplay = val + 1
          if (pageDisplay >= grouping.bottom && pageDisplay <= grouping.top) {
            return (
              <li
                key={'page:number:' + val}
                onClick={(): unknown => {
                  onSelect(pageDisplay)
                  return
                }}
              >
                <a
                  className={`neutron-pagination__page ${
                    currentPage === pageDisplay
                      ? 'neutron-pagination__active'
                      : ''
                  }`}
                  aria-label="Go to Page 1"
                  href="#"
                >
                  {pageDisplay}
                </a>
              </li>
            )
          }
        })}
        {!(currentPage === roundedPages) &&
          currentPage < roundedPages &&
          roundedPages > 5 && (
            <>
              <li>
                <i className="material-icons neutron-pagination__overflow">
                  more_horiz
                </i>
              </li>
              <li
                onClick={() => {
                  if (currentPage === roundedPages) return
                  setGrouping({ bottom: pages - 5, top: pages })
                  onSelect(roundedPages)
                }}
              >
                <a
                  className={`neutron-pagination__page ${
                    currentPage === roundedPages
                      ? 'neutron-pagination__active'
                      : ''
                  }`}
                  aria-label={`Go to Page ${roundedPages}`}
                  href="#"
                >
                  {roundedPages}
                </a>
              </li>
            </>
          )}

        <li
          onClick={() => {
            if (currentPage + 1 > roundedPages) return
            if (currentPage < roundedPages) {
              onSelect(currentPage + 1)
            }

            if (currentPage + 1 > grouping.top) {
              setGrouping({ bottom: currentPage + 1, top: currentPage + 5 })
            }
          }}
        >
          <a href="#" className="neutron-pagination__next">
            <i className="material-icons">chevron_right</i>
          </a>
        </li>
      </ul>
    </nav>
  )
}
