import {
  FETCHING_AUTH_USER,
  GET_AUTH_TOKEN,
  GET_AUTH_USER,
} from '../constants/actionTypes'
import { AnyAction } from 'redux'
import { AuthStateProps } from '../../interfaces/auth'

const initialState: AuthStateProps = {
  fetchingAuthUser: false,
  token: '',
  hca34: '',
  firstName: '',
  lastName: '',
  authorizedOrganizations: [],
  options: {
    Company: [],
    Group: [],
    Division: [],
    Facility: [],
  },
}

const auth = (state = initialState, action: AnyAction): AuthStateProps => {
  switch (action.type) {
    case GET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    case GET_AUTH_USER:
      return {
        ...state,
        hca34: action.payload.hca34,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        authorizedOrganizations: action.payload.authorizedOrganizations,
        options: action.payload.options,
      }
    case FETCHING_AUTH_USER:
      return { ...state, fetchingAuthUser: action.payload }
    default:
      return state
  }
}

export default auth
