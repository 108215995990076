import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Flex } from 'rebass'
import { UnsavedEdits } from './UnsavedEdits'
import { RemoveRow } from './RemoveRow'
import { ModalProps } from '../../../interfaces/ui'
import { DeleteUser } from './DeleteUser'

export const Modal: ({ type }: Partial<ModalProps>) => React.ReactElement = ({
  type,
}: Partial<ModalProps>): ReactElement => {
  const getModalType = () => {
    switch (type) {
      case 'unsavedEdits':
        return <UnsavedEdits />
      case 'removeRow':
        return <RemoveRow />
      case 'deleteUser':
        return <DeleteUser />
      default:
        return <div>HEY</div>
    }
  }
  return (
    <Flex
      style={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.3)',
        zIndex: 10001,
      }}
    >
      {getModalType()}
    </Flex>
  )
}
