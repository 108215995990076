import React, { ReactElement } from 'react'

interface TabProps {
  label: string
  type?: 'dark' | 'light'
  size?: string
  isActive: boolean
  onSelect?: () => void
  style?: object
}

export const Tab: React.FC<TabProps> = ({
  children,
  label,
  size,
  type,
  isActive,
  onSelect,
  style,
}): ReactElement => {
  return (
    <div className={`neutron-tablist`} role="tablist" style={{ ...style }}>
      <button
        className={`
        ${isActive ? 'neutron-tab--is-active' : ''}
        neutron-tablist__tab 
            ${type === 'dark' ? 'neutron-tablist__tab-on-dark' : ''}
            neutron-tablist__tab-${size && size.toLowerCase()}
             ${
               isActive && type === 'dark'
                 ? 'neutron-tablist__tab-on-dark--is-active'
                 : ''
             }
             ${
               isActive && type !== 'dark'
                 ? 'neutron-tab--is-active neutron-tablist__tab-on-light--is-active'
                 : ''
             }
        `}
        role="tab"
        aria-selected={isActive}
        aria-controls="panel-name1"
        id="tab-name1"
        onClick={(): void => onSelect && onSelect()}
      >
        {children}
        <span
          className={`${
            type === 'dark'
              ? 'neutron-tablist__tab-text-on-dark'
              : 'neutron-tablist__tab-text'
          }
          ${isActive &&
            type === 'dark' &&
            'neutron-tablist__tab-text-on-dark--is-active'}`}
        >
          {label}
        </span>
      </button>
    </div>
  )
}
